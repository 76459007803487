import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  store: (payload) => {
    return _http.post(`/api/audio-visual/media-subject-video`, payload);
  },
  update: (payload) => {
    return _http.put(`/api/audio-visual/media-subject-video`, payload);
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/media-subject-video/${id}`);
  },
  presentationFileS3Key: (id, payload) => {
    return _http.patch(`/api/audio-visual/media-subject-video?id=${id}`, payload);
  },
  showByRecordingMediaFile: (id) => {
    return _http.get(`/api/audio-visual/media-subject-video/show-by-recording-media-file/${id}`)
  },
  searchByTeacher: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if(options.search)
        _parans += `&search=${options.search}`;
      if (options.teacher_id)
        _parans += `&data.teacher_id=${options.teacher_id}`;
      if (options.is_future_transmission)
        _parans += `&data.is_future_transmission=${options.is_future_transmission}`;
        if (options.size)
          _parans += `&size=${options.size}`;
      if (options.orderByKey)
        _parans += `&order_by=${options.orderByKey}`;
      if (options.ascOrDes)
        _parans += '&asc';
    }
    return _http.get(`/api/audio-visual/media-subject-video/show-by-teacher${_parans}`)
  },
  getUrlFile: (file_name, type) => {
    return _http.get(`/api/audio-visual/media-subject-video/get-url-file?file_name=${file_name}&type=${type}`)
  },
  reorderList: (payload) => {
    return _http.put(`/api/audio-visual/media-subject-video/order`, payload);
  }
}