import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  
  // upsert: (payload) => {
  //   return _http.put('/api/audio-visual/media-subject-pending', payload)
  // }
  
  filter: (recording_media_subject_pending_id,media_subject_id) => {
    //let _parans = `?page=${page - 1}`;
    let _parans = `?page=0`;
      if (recording_media_subject_pending_id)
         _parans += `&data.recording_media_subject_pending_id=${recording_media_subject_pending_id}`;
      if (media_subject_id)
        _parans += `&data.media_subject_id=${media_subject_id}`;
      // if (order_by)
      //   _parans += `&order_by=${order_by}`;
      // if (asc)
      //   _parans += '&asc';
    return _http.get(`/api/audio-visual/media-subject-pending/filter${_parans}`)
  },
  create: (payload) => {
    return _http.post('/api/audio-visual/media-subject-pending', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/media-subject-pending/${id}`)
  },
}