<template>
  <b-overlay :variant="skin" :show="loading" rounded="sm">
    <b-row>
      <b-col class="mb-50" cols="12">
        <feather-icon
          :icon="viewMode ? 'Trash2Icon' : 'EyeOffIcon'"
          size="18"
          class="float-right mx-1"
          v-b-tooltip.hover.top="'Exibir Excluídos'"
          @click="viewMode = !viewMode"
        />
      </b-col>
      <b-col cols="12">
        <FileUpload
          icon="VideoIcon"
          :fileType="fileType.join()"
          :multiple="true"
          :viewExcluded="viewMode"
          :listMedias="list"
          @fileChange="fileChange"
          @removeMediaItem="removeMediaItem"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapMutations } from "vuex";
import { BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";
import AWS from "aws-sdk";
import FileUpload from "@/components/FileUpload.vue";
import _recordingMediaService from "@/services/audiovisual/recording-media";
import _mediaFilesService from "@/services/audiovisual/recording-media-files";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    FileUpload,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    list: {
      handler(newValue, _oldValue) {
        this.$emit("changeItems", newValue);
      },
      deep: true,
    },
    items: {
      handler(newValue, _oldValue) {
        this.list = newValue;
      },
    },
  },
  data() {
    return {
      fileType: [".mp4", ".mov", ".mkv", ".mp3", ".flac", ".wav", ".aac", "mqa", ".m4a"],
      list: [],
      viewMode: true,
    };
  },
  destroyed() {
    this.UPDATE_CURRENT_TOOLBAR("none");
  },
  methods: {
    ...mapMutations({
      UPDATE_VALUE: "toolbar/progress/UPDATE_VALUE",
      UPDATE_CURRENT_TOOLBAR: "toolbar/UPDATE_CURRENT_TOOLBAR",
    }),
    fileChange(files) {
      // VERIFICA A EXTENÇÃO DOS ARQUIVOS
      files.forEach((f) => {
        let ext = f.name.split(".");
        ext = "." + ext[ext.length - 1];
        if (!this.fileType.includes(ext)) {
          f.validate = true;
          this.$utils.toastWarning(
            "Extensão Inválida",
            `A extensão ${ext} não é aceita`
          );
        }
      });

      // VERIFICA SE O ARQUIVO JÁ FOI ADICIONADO
      this.list.forEach((i) => {
        files.forEach((f) => {
          if (f.name == i.name) {
            f.validate = true;
            this.$utils.toastWarning(
              "Arquivo Duplicado",
              `O arquivo '${f.name}' já consta na lista`
            );
          }
        });
      });

      // ADICIONA UM NOVO OBJETO NA LISTA
      if (files) {
        files.forEach((_file) => {
          if (!_file.validate) {
            this.list.push({
              key: this.getKey(_file.name),
              bucket: process.env.VUE_APP_AWS_BUCKET_VIDEO,
              region: process.env.VUE_APP_AWS_BUCKET_REGION,
              preview: URL.createObjectURL(_file),
              type: _file.type,
              name: _file.name,
              file: _file,
              progress: 0,
              erro: "",
            });
          }
        });
      }

      this.execUpload();
    },
    getKey(name) {
      let key = `${Math.random().toString(16).substr(2)}${Math.random()
        .toString(16)
        .substr(2)}`;
      let ext = name.split(".")[name.split(".").length - 1];
      return `${key}.${ext}`;
    },
    removeMediaItem(item) {
      this.$swal({
        title: item.excluded
          ? "Deseja resgatar esse item da lixeira?"
          : "Deseja remover esse item da lista?",
        text: "-",
        icon: item.excluded ? "warning" : "error",
        showCancelButton: true,
        confirmButtonText: item.excluded
          ? "Sim, quero resgatar!"
          : "Sim, quero remover!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (item.complete) {
            if (item.uploaded) {
              item.loading = true;
              const payload = [
                {
                  path: "/excluded",
                  op: "replace",
                  value: !item.excluded,
                },
              ];
              _mediaFilesService
                .logicalExclusion(item.recordingMediaFilesID, payload)
                .then((res) => {
                  item.excluded = res.content.excluded;
                })
                .catch((error) => this.$utils.toastError("Notificação", error))
                .finally(() => (item.loading = false));
            } else {
              this.list = this.list.filter((f) => f.key !== item.key);
            }
          } else {
            this.$utils.toastWarning(
              "Envio em Progresso",
              "Pedimos, por favor, que aguarde e tente novamente"
            );
          }
        }
      });
    },
    sendFileS3(_postfile) {
      return new AWS.S3.ManagedUpload({
        params: {
          ContentType: _postfile.type,
          Bucket: _postfile.bucket,
          Body: _postfile.file,
          Key: `${process.env.VUE_APP_AWS_BUCKET_DIR}/${_postfile.key}`,
        },
      });
    },
    deleteFileS3(_request) {
      var bucketInstance = new AWS.S3();
      var params = {
        Bucket: _request[0].bucket,
        Key: _request[0].key,
      };
      bucketInstance.deleteObject(params, function (err, data) {
        // if (data) {
        //   console.log("File deleted successfully");
        // } else {
        //   console.log("Check if you have sufficient permissions : " + err);
        // }
      });
    },
    execUpload() {
      this.iniciarConfigs();
      for (let index = 0; index < this.list.length; index++) {
        if (!this.list[index].complete && this.list[index].progress == 0) {
          this.list[index].erro = ""; // description
          const Req = this.list[index]; // post aws
          this.sendFileS3(Req)
            .on("httpUploadProgress", (evt) => {
              let uo = this.list.filter((f) => f.key === Req.key)[0];
              uo.progress = Math.round((evt.loaded / evt.total) * 100);
              this.uploadProgress();
            })
            .send((err) => {
              if (err) {
                let uo = this.list.filter((f) => f.key === Req.key)[0];
                uo.erro = err;
              }
              let up = this.list.filter((f) => f.key === Req.key)[0]; // recuperar objeto
              up.complete = true;
            });
        }
      }
    },
    uploadProgress() {
      const inUpload = this.list.filter((f) => !f.inCloud);
      const qtdUpload = inUpload.length;
      let loaded = 0;

      inUpload.forEach((f) => {
        loaded += f.progress;
      });
      this.UPDATE_CURRENT_TOOLBAR("progress-bar");
      this.UPDATE_VALUE(Math.round(loaded / qtdUpload));
    },
    iniciarConfigs() {
      AWS.config.update({
        accessKeyId: process.env.VUE_APP_AWS_ACESSKEY_ID,
        secretAccessKey: process.env.VUE_APP_AWS_ACESSKEY_SECRET,
      });
    },
  },
};
</script>
