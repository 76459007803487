<template>
  <form enctype="multipart/form-data">
    <div
      class="dropbox"
      :class="[ lightMode ? 'skin-light' : 'skin-dark' ]"
      :style="[
        width
          ? { width: width + 'px', height: height + 20 + 'px' }
          : { minHeight: '245px' },
        listMedias.length > 0
          ? ''
          : { justifyContent: 'center', display: 'flex' }
      ]"
    >
      <input
        type="file"
        @change="filesChange($event.target.files)"
        :accept="fileType"
        class="input-file"
        :style="
          width
            ? { width: width + 'px', height: height + 20 + 'px' }
            : { width: '99%', minHeight: '95%' }
        "
        :multiple="multiple"
      />
      <p v-if="!urlImage && listMedias.length == 0">
        <feather-icon style="margin-bottom: 5px" :icon="icon" size="25" />
        <br />
        Arraste e solte aqui<br />
        ou clique para buscar
      </p>

      <!-- Se for imagem -->
      <img
        v-if="urlImage"
        :src="urlImage"
        class="imagem img-fluid cursor-pointer"
        :style="
          width
            ? { width: width + 'px', height: height + 'px' }
            : { height: '225px' }
        "
      />
      <div v-if="urlImage" class="title">Preview 16:9</div>

      <!-- Se for Vídeo -->
      <b-row v-if="listMedias.length > 0" class="m-0">
        <div
          v-for="(item, index) in listMedias"
          :key="index"
          :class="[item.excluded && viewExcluded ? 'd-none' : 'video-card', `skin-video-${skin}`]"
        >
          {{ item.name }}
          <feather-icon
            class="m-0"
            :icon="item.excluded ? 'PlusIcon' : 'XIcon'"
            style="position: relative; top: -15px; right: -10px"
            @click="removeMediaItem(item)"
          />
          <b-overlay
            :show="item.loading"
            :variant="skin"
            rounded="sm"
            spinner-small
          >
            <b-progress
              style="margin-top: 3px"
              max="100"
              :variant="item.erro ? 'danger' : item.excluded ? 'warning' : 'success'"
              :class="item.excluded ? 'progress-bar-warning' : 'progress-bar-success'"
              :animated="item.uploaded ? false : true"
            >
              <b-progress-bar :value="item.erro ? '100' : item.progress">
                <strong
                  v-if="item.excluded"
                  style="font-size: 7pt"
                >
                  Apagado
                </strong>
                <strong
                  v-else-if="item.erro || item.progress == 100"
                  style="font-size: 7pt"
                >
                  {{ item.erro ? "Error!" : item.uploaded ? "Salvo" : "Pronto!" }}
                </strong>
              </b-progress-bar>
            </b-progress>
          </b-overlay>
        </div>
      </b-row>
    </div>
  </form>
</template>

<script>
import { BProgress, BProgressBar, BBadge, BOverlay } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig()
    return {
      skin
    }
  },
  components: {
    BProgress,
    BProgressBar,
    BBadge,
    BOverlay
  },
  data() {
    return {
      selectedFile: null,
      urlImage: null,
    };
  },
  props: {
    icon: {
      type: String,
      default: "ImageIcon",
    },
    image: {
      type: String,
      default: "",
    },
    listMedias: {
      type: Array,
      default: () => [],
    },
    fileType: {
      type: String,
      default: "image/*",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      required: false,
    },
    viewExcluded: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    removeMediaItem(item) {
      this.$emit("removeMediaItem", item);
    },
    filesChange(fileList) {
      if (!this.multiple) {
        this.selectedFile = fileList[0];
        this.urlImage = URL.createObjectURL(fileList[0]);
      } else {
        this.selectedFile = fileList;
      }
      this.$emit("fileChange", this.selectedFile);
    },
  },
  watch: {
    image() {
      if (this.image) {
        this.urlImage = this.image;
      }
    },
  },
  computed: {
    height() {
      if(this.width){
        return Math.round((this.width / 16) * 9)
      }
    },
    lightMode() {
      return this.skin == 'light' ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -5px;
  color: dimgray;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
}

.skin-light {
  background: #F5F5F5;
}

.skin-dark {
  background: #333A3E;
  color: #d0d2d6;
}
.skin-video-light {
  background: #F5F5F5;
}

.skin-video-dark {
  background: #2E353B;
}
.imagem {
  display: block;
}

.dropbox .title {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0px 5px;
  border-radius: 5px;
  font-size: 10pt;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  position: absolute;
  cursor: pointer;
}

.dropbox p {
  font-size: 10pt;
  text-align: center;
  align-self: center;
}

.video-card {
  text-align: center;
  padding: 15px;
  margin: 5px;
  min-width: 100px;
  display: block;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(87, 87, 87, 0.2);
  height: 65px;
}
</style>