var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":_vm.skin,"show":_vm.loading,"rounded":"sm"}},[_c('draggable',{attrs:{"role":"tablist","handle":".draggable-handle"},on:{"change":_vm.updateOrder},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},_vm._l((_vm.items),function(item,i){return _c('b-card',{key:i,class:['accordion-card', ("accordion-card-bg-" + _vm.skin)],attrs:{"no-body":""}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("accordion-" + i)),expression:"`accordion-${i}`"}],staticClass:"accordion-header justify-content-between align-items-center",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"draggable-handle d-flex justify-content-center align-items-center",class:[{ 'show-handle': _vm.currentAccordion != null }]},[_c('b-icon',{attrs:{"icon":"grip-vertical","font-scale":"1.6"}})],1),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"accordion-header-title"},[_vm._v(" "+_vm._s(("Material " + (i + 1)))+" "),_c('br'),_vm._v(" "+_vm._s(("Arquivo: " + (item.name)))+" "),_c('span',{staticStyle:{"color":"#9aa1b0"}},[_vm._v(" "+_vm._s(("(" + (item.original_filename) + ")"))+" ")])])])]),_c('div',[_c('b-badge',{staticClass:"mr-2",attrs:{"variant":item.type_media == 1 ? 'success' : 'info'}},[_vm._v(" "+_vm._s(_vm.descType(item.type_media))+" ")]),_c('feather-icon',{staticClass:"mr-1",class:{
              active: _vm.currentAccordion == i,
              inactive: _vm.currentAccordion != i,
            },attrs:{"icon":"ChevronUpIcon","size":"18"}})],1)]),_c('b-collapse',{attrs:{"id":("accordion-" + i),"accordion":"my-accordion","role":"tabpanel"},on:{"show":function($event){return _vm.activeAccordion(i, item)},"hide":function($event){return _vm.inactiveAccordion(i, item)}}},[(item.pip)?_c('b-card-body',{staticClass:"accordion-body"},[_c('b-overlay',{attrs:{"variant":_vm.skin,"show":!item.pip.duration || !_vm.mediaLoaded,"rounded":"sm"}},[_c('b-row',{staticClass:"pt-1 pb-50",style:({ backgroundColor: _vm.lightMode ? '#f7f7f7' : '#2E353B' })},[_c('b-col',{attrs:{"cols":"auto"}},[_c('video',{staticStyle:{"background":"#c4c4c4"},attrs:{"src":item.preview,"id":("media-" + i),"poster":item.type_media == 1
                      ? require('@/assets/images/svg/audio_play.svg')
                      : null,"height":"440"},on:{"seeking":function($event){return _vm.currentTimeChange(item, i, true)},"timeupdate":function($event){return _vm.currentTimeChange(item, i, true)},"loadedmetadata":function($event){_vm.mediaLoaded = true}}}),_c('div',[(item.pip.duration)?_c('b',[_vm._v(" "+_vm._s(_vm.formatterDurarion(item))+" ")]):_vm._e()])]),_c('b-col',[(
                    _vm.appointment &&
                    ((_vm.appointment.products &&
                      _vm.appointment.products.length > 0) ||
                      _vm.appointment.discipline_name)
                  )?_c('div',{class:[
                    'mb-1',
                    'appointment-card',
                    ("appointment-card-bg-" + _vm.skin) ]},[(
                      _vm.appointment.products && _vm.appointment.products.length > 0
                    )?_c('div',[_c('b',[_vm._v("Produto(s) sinalizado(s):")]),_vm._l((_vm.appointment.products),function(product,index){return _c('b-badge',{key:index,staticClass:"m-25",attrs:{"variant":"light-info"}},[_vm._v(" "+_vm._s(product)+" ")])})],2):_vm._e(),(_vm.appointment && _vm.appointment.discipline_name)?_c('div',[_c('b',[_vm._v("Disciplina sinalizada:")]),_c('b-badge',{staticClass:"m-25",attrs:{"variant":"light-warning"}},[_vm._v(" "+_vm._s(_vm.appointment.discipline_name)+" ")])],1):_vm._e(),(_vm.appointment && _vm.appointment.classification_name)?_c('div',[_c('b',[_vm._v("Classificação sinalizada:")]),_c('b-badge',{staticClass:"m-25",attrs:{"variant":"light-warning"}},[_vm._v(" "+_vm._s(_vm.appointment.classification_name)+" ")])],1):_vm._e()]):_vm._e(),_c('UploadPDF',{attrs:{"file":item.pdf,"patchSave":true,"dirS3":_vm.DIR_FILE_UPLOAD,"fileType":_vm.fileTypeUpload,"mediaFileID":item.media_file_id,"limitSizeFile":_vm.LIMIT_SIZE_FILE_UPLOAD},on:{"initUpload":function($event){_vm.inUploadPDF = true},"uploadFail":function($event){_vm.inUploadPDF = false},"newFile":function (file) {
                      item.pdf = file;
                      _vm.inUploadPDF = false;
                    },"remove":function($event){return _vm.clearPdfPages(item.timeline.subjects)}}}),_c('b-button',{staticClass:"mr-1",staticStyle:{"position":"absolute","bottom":"25px","right":"0px","min-width":"120px"},attrs:{"variant":"info","disabled":_vm.inUploadPDF},on:{"click":function($event){return _vm.newSubject(item)}}},[(!_vm.inUploadPDF)?_c('div',[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Assunto")])],1):_c('b-spinner',{attrs:{"small":""}})],1)],1)],1)],1),(
              item.pip.duration &&
              _vm.mediaLoaded &&
              item.timeline &&
              item.timeline.subjects &&
              item.timeline.subjects.length > 0
            )?_c('section',[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('Subjects',{attrs:{"group":"subjects","mediaDuration":item.pip.duration,"items":item.timeline.subjects,"pdf":item.pdf,"mediaFilesID":item.recordingMediaFilesID,"activeAutoSave":_vm.activeAutoSave,"appointmentId":_vm.appointmentId}})],1)],1)],1):_c('p',{staticClass:"text-center mt-2"},[_vm._v(" Nenhum assunto vinculado ao material ")])],1):_vm._e()],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }