<template>
  <div>
  
    <b-overlay
      :show="showOverlay"
      :variant="skin"
      opacity="1"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <!-- Pendencies-->
      <b-row style="min-height: 200px; margin-top: 10px">
        <b-col cols="12" v-if="associatedPendencyList.length != 0">
          <b-list-group v-for="(element, id) in associatedPendencyList" :key="id">
            <b-list-group-item class="p-50 mb-1">
              <div>
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="ml-25">{{ element.pending_subject }}</p>
                  </div>
                  <div class="d-flex justify-content-end" style="min-width: 150px;">
                    <div>
                      <p class="ml-25" v-b-tooltip.hover.top="'Id do agendamento'">#{{ element.appointment_id }}</p>
                    </div>
                    <div>
                      <feather-icon
                        v-if="!element.pending_completed"
                        :id="`modal-new-subjects-${element.id}`"
                        icon="CheckSquareIcon"
                        v-b-tooltip.hover.top="'Concluir pendencia'"
                        class="ml-1 mr-50"
                        @click="completePendencyModal(element)"
                        size="20"
                        style="cursor: pointer; color: blue;"
                      />
                      <feather-icon
                        v-if="!element.pending_completed"
                        :id="`modal-new-subjects-${element.id}`"
                        icon="Trash2Icon"
                        v-b-tooltip.hover.top="'Remover'"
                        class="text-danger mr-50"
                        @click="removePendencyModal(element)"
                        size="20"
                        style="cursor: pointer;"
                        
                      />
                      <feather-icon
                        v-if="element.pending_completed"
                        :id="`modal-new-subjects-${element.id}`"
                        icon="CheckCircleIcon"
                        v-b-tooltip.hover.top="'Pendencia concluida'"
                        class="ml-1 mr-50"
                        size="20"
                        style="color: green;"
                      />
                  </div>
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col v-else class="mt-1" style="text-align: center">
          Nenhuma pendencia foi associada
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 8px; margin-top: 20px">
        <b-col style="text-align: left">
          <b-button variant="info" @click="loadPendencyList">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Adicionar</span>
          </b-button>
        </b-col>
      </b-row>
      <!-- End Pendencies -->

      <!-- Pendencies list  -->
      <b-modal
        ref="modal-pendencies-list"
        title="Selecione uma pendência.."
        hide-footer
        scrollable
      >
        <b-row style="min-height: 200px; margin-top: 10px; cursor: pointer;">
          <b-col
            cols="12"
            v-if="this.availablePendencyList != null && this.availablePendencyList.length != 0"
          >
            <b-list-group v-for="(element, id) in this.availablePendencyList" :key="id">
              <b-list-group-item class="p-50 mb-1" @click="addPendencyModal(element)">
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="ml-25">{{ element.subject }}</p>
                  </div>
                  <div>
                    <p class="ml-25" v-b-tooltip.hover.top="'Id do agendamento'">#{{ element.appointment_id }}</p>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col v-else class="mt-1" style="text-align: center">
            Nenhuma pendência disponível
          </b-col>
        </b-row>
      </b-modal>
      <!-- End Pendencies list-->
    </b-overlay>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import draggable from "vuedraggable";
import {
  BModal,
  BOverlay,
  BListGroup,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BRow,
  BCol,
  BIcon,
  BIconGripVertical,
  VBTooltip,
} from "bootstrap-vue";
import _recordingMediaSubjectPendingService from "@/services/audiovisual/recording-media-pending-subject-service";
import _mediaSubjectPendingService from "@/services/audiovisual/media-subject-pending-service";

import _recordingPendingService from "@/services/audiovisual/recording-pending";

import VueSlider from "vue-slider-component";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BModal,
    draggable,
    VueSlider,
    BOverlay,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BRow,
    BCol,
    BIcon,
    BIconGripVertical,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {},
  data() {
    return {
      media_subject_id: null,
      teacher_id: null,
      associatedPendencyList: [],
      availablePendencyList: [],
      showOverlay: false,
    };
  },
  props: {
    subjectActive: {
      required: true,
    },
  },
  created() {
    this.media_subject_id = this.subjectActive.id;
    this.teacher_id = this.subjectActive.teacherSelected.value;
    this.loadPendencies();
  },
  methods: {
    // Busca as pendencias associadas ao assunto no banco de dados
    loadPendencies() {
      this.showOverlay = true;
      _mediaSubjectPendingService
        .filter(null, this.media_subject_id)
        .then((res) => {
          this.associatedPendencyList = res.content;
          this.showOverlay = false;
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.showOverlay = false;
        });
    },
    // Busca as pendencias disponiveis para associar ao assunto
    loadPendencyList() {
      this.showOverlay = true;
      _recordingMediaSubjectPendingService
        .filter(null, this.teacher_id, false) //somente pendencias que não foram concluidas do professor do assunto
        .then((res) => {
          if (res.content) {
            this.availablePendencyList = this.filterAssociatedPendencies(res.content);
            this.showOverlay = false;
            this.$refs["modal-pendencies-list"].show();
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
          this.showOverlay = false;
        });
    },
    // Filtra as pendencias já associadas
    filterAssociatedPendencies(content) {
      if (this.associatedPendencyList.length > 0 && content?.length > 0) {
        // Somente as pendencias que ainda não estao associadas
        return content.filter((f) =>
          !this.associatedPendencyList
            .map((m) => m.recording_media_subject_pending_id)
            .includes(f.id));
      }
      return content;
    },
    // Abre popup para confirmar se deseja adicionar a pendencia ao assunto e se deseja concluir a pendencia
    addPendencyModal(mediaSubjectPending) {
      this.$refs["modal-pendencies-list"].hide();
      const description = mediaSubjectPending.subject;

      this.$swal({
        title: "Associar a pendência?",
        html: `
        <div class="mt-50 mb-1 h4">
          Deseja associar a pendência \"${description}\"?
        </div>
        <div class="mt-50 mb-1 h3">
          <input type="checkbox" name="conclude" id="chkConclude" /> <label for="chkConclude"> Concluir pendência </label>
        </div>
        <div class="mt-50 mb-1 h4">
          <label>Obs.: Não será possível reverter ao concluir a pendência</label>
        </div>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        width: '800px',
      }).then((result) => {
        if (result.isConfirmed) {
          let chkConclude = document.getElementById("chkConclude");
          this.addMediaSubjectPending(mediaSubjectPending, chkConclude.checked);
        }
      });
    },
    // Associa uma pendencia ao assunto e completa a pendencia se solicitado
    addMediaSubjectPending(mediaSubjectPending, completePendency = false) {
      const payload_create = {
        records: [
          {
            recording_media_subject_pending_id: mediaSubjectPending.id,
            media_subject_id: this.media_subject_id,
          },
        ]
      };
      this.showOverlay = true;
      _mediaSubjectPendingService
        .create(payload_create)
        .then(() => {
          this.$utils.toast(
            "Notificação",
            "Pendência associada com sucesso."
          );
          if (completePendency)
          {
            this.changePendencyToComplete(mediaSubjectPending);
          }
          else
          {
            this.showOverlay = false;
            this.loadPendencies();
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
          this.showOverlay = false;
        });
    },
    // Altera o status da pendência para concluída no banco de dados
    changePendencyToComplete(mediaSubjectPending) {
      let payload_change = {
        data: {
          id: mediaSubjectPending.recording_media_pending_id,
          completed: true,
        },
      };
      this.showOverlay = true;
      _recordingPendingService
        .changeCompleted(payload_change)
        .then(() => {
          this.$utils.toast(
            "Notificação",
            "Pendência concluída com sucesso."
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.showOverlay = false;
          this.loadPendencies();
        });
    },
    // Abre poppup de confirmação para remoção da associação da pendência com o assunto
    removePendencyModal(mediaSubjectPending) {
      const description = mediaSubjectPending.pending_subject;

      this.$swal({
        title: "Remover associação de pendência?",
        text: `Deseja remover a associação da pendência \"${description}\"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, remover pendência!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed)
        {
          this.removeMediaSubjectPending(mediaSubjectPending); 
        }
      });
    },
    // Remove a associação da pendência com o assunto no banco de dados
    removeMediaSubjectPending(mediaSubjectPending) {
      this.showOverlay = true;
      _mediaSubjectPendingService
        .delete(mediaSubjectPending.id)
        .then(() => {
          this.$utils.toast(
            "Notificação",
            "Associação de pendência removida com sucesso."
          );
          this.showOverlay = false;
          this.loadPendencies();
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
          this.showOverlay = false;
        });
    },
    // Abre popup para confirmar se deseja concluir a pendencia
    completePendencyModal(mediaSubjectPending) {
      const description = mediaSubjectPending.pending_subject;

      this.$swal({
        title: "Concluir pendência?",
        text: `Deseja concluir a pendência \"${description}\"? Isso não será reversível!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, concluir pendência!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed)
        {
          this.changePendencyToComplete(mediaSubjectPending);
        }
      });
    },
  },
};
</script>

<style scoped>
.sandbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.label {
  display: block;
  position: absolute;
  top: -18px;
  left: 25px;
  background: white;
  padding: 0px 7px 0px 7px;
}
.draggable-handle {
  display: block;
  background: #5a6172;
  color: white;
  border-radius: 5px 0px 0px 5px;
  min-width: 30px;
  cursor: move;
  z-index: 100;
}
.draggable-body {
  padding: 0.75rem 1.25rem;
  cursor: auto;
}
.draggable-item {
  border-width: 1px;
  border-radius: 5px;
}
.list-group-item:last-child {
  border-bottom-width: 1px;
}
.draggable-handle .index {
  position: absolute;
  top: 10px;
}
</style>
