import HttpService from "../HttpService";
const _http = new HttpService();

export default {
  create: (payload) => {
    return _http.post("/api/audio-visual/media-files", payload);
  },
  findByRecording: (id) => {
    return _http.get(`/api/audio-visual/media-files/show-by-recording/${id}`);
  },
  logicalExclusion: (id, payload) => {
    return _http.patch(`/api/audio-visual/recording-media-files?id=${id}`, payload);
  },
  reorderList: (payload) => {
    return _http.put(`/api/audio-visual/recording-media-files/reorder-list`, payload);
  },
  getUrlFile: (file_name, type) => {
    return _http.get(`/api/audio-visual/media-files/get-url-file?file_name=${file_name}&type=${type}`);
  },
  saveDuration: (id, payload) => {
    return _http.patch(`/api/audio-visual/media-files?id=${id}`, payload);
  },
  findAllByRecording: (id) => {
    return _http.get(`/api/audio-visual/media-files/show-all-by-recording/${id}`);
  },
};