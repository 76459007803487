import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  store: (payload) => {
    return _http.post(`/api/audio-visual/media-subject`, payload);
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/media-subject/${id}`);
  },
  update: (payload) => {
    return _http.put(`/api/audio-visual/media-subject`, payload);
  },
  reorderList: (payload) => {
    return _http.put(`/api/audio-visual/media-subject/order`, payload);
  }
}