<template>
  <div>
    <validation-observer ref="subject">
      <b-row class="draggable-body">
        <div class="sync-icon">
          <feather-icon
            v-if="Number(subject.id) ? false : true"
            icon="CircleIcon"
            size="16"
            v-b-tooltip.hover.top="'Rascunho'"
          />
          <b-spinner v-else-if="loading" small />
          <feather-icon
            style="color: red"
            v-else-if="noSync"
            icon="AlertCircleIcon"
            size="16"
            v-b-tooltip.hover.top="'Pendente'"
          />
          <feather-icon
            style="color: green"
            v-else
            icon="CheckCircleIcon"
            size="16"
            v-b-tooltip.hover.top="'Salvo'"
          />
        </div>

        <b-col class="mb-25">
          <span>Empresa *</span>
          <validation-provider
            #default="{ errors }"
            name="Empresa"
            rules="required"
          >
            <v-select
              style="min-width: 300px"
              v-model="subject.companiesSelected"
              :options="companies"
              autocomplete="off"
              :clearable="false"
              :state="errors.length > 0 ? false : null"
              @input="changeCompany(subject, true)"
            >
              <template #selected-option="{ label }">
                <div
                  class="d-inline-block text-truncate"
                  style="max-width: 340px"
                >
                  {{ label }}
                </div>
              </template>
            </v-select>
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          </validation-provider>
        </b-col>

        <b-col class="mb-25">
          <span>Projeto *</span>
          <validation-provider
            #default="{ errors }"
            name="Projeto"
            rules="required"
          >
            <v-select
              style="min-width: 300px"
              v-model="subject.projectSelected"
              :options="subject.searchProject.projects"
              :clearable="false"
              autocomplete="off"
              :state="errors.length > 0 ? false : null"
              :filterable="false"
              @search="searchProject"
              :loading="subject.searchProject.loading"
              @input="changeProject(subject, true)"
            >
              <template #selected-option="{ label }">
                <div
                  class="d-inline-block text-truncate"
                  style="max-width: 340px"
                >
                  {{ label }}
                </div>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em style="opacity: 0.5" v-else>Digite o Projeto</em>
              </template>
            </v-select>
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          </validation-provider>
        </b-col>

        <b-col class="mb-25" v-if="!appointmentId">
          <span>Tipo de Demanda *</span>
          <validation-provider
            #default="{ errors }"
            name="Demanda"
            rules="required"
          >
            <v-select
              style="min-width: 300px"
              v-model="subject.jobTypeSelected"
              :options="jobTypes"
              autocomplete="off"
              :clearable="false"
              :state="errors.length > 0 ? false : null"
              :disabled="!subject.projectSelected"
              @input="changeJobType(subject.jobTypeSelected, true)"
            >
              <template #selected-option="{ label }">
                <div
                  class="d-inline-block text-truncate"
                  style="max-width: 340px"
                >
                  {{ label }}
                </div>
              </template>
            </v-select>
          </validation-provider>
        </b-col>

        <b-col class="mb-25">
          <span>Produto *</span>
          <validation-provider
            #default="{ errors }"
            name="Produto"
            rules="required"
          >
            <v-select
              style="min-width: 300px"
              v-model="subject.productSelected"
              :options="products"
              autocomplete="off"
              :clearable="false"
              :state="errors.length > 0 ? false : null"
              :disabled="!subject.companiesSelected"
            >
              <template #selected-option="{ label }">
                <div
                  class="d-inline-block text-truncate"
                  style="max-width: 340px"
                >
                  {{ label }}
                </div>
              </template>
            </v-select>
          </validation-provider>
        </b-col>

        <b-col class="mb-25">
          <span>Disciplina *</span>
          <validation-provider
            #default="{ errors }"
            name="Disciplina"
            rules="required"
          >
            <v-select
              style="min-width: 300px"
              v-model="subject.disciplineSelected"
              :options="subject.searchDiscipline.disciplines"
              :clearable="false"
              autocomplete="off"
              :filterable="false"
              @search="searchDiscipline"
              :loading="subject.searchDiscipline.loading"
              :state="errors.length > 0 ? false : null"
            >
              <template #selected-option="{ label }">
                <div
                  class="d-inline-block text-truncate"
                  style="max-width: 340px"
                >
                  {{ label }}
                </div>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em v-else style="opacity: 0.5">Digite a Disciplina</em>
              </template>
            </v-select>
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          </validation-provider>
        </b-col>

        <b-col class="mb-25">
          <span
            >Classificação
            <b-spinner v-if="subject.searchClassification.loading" small />
          </span>
          <label>(Base de Dados da Plataforma)</label>
          <validation-provider
            #default="{ errors }"
            name="Classificação"
            :rules="
              'needSaving:' +
              subject.classificationSelected.value +
              ',' +
              subject.classificationSelected.label
            "
            style="width: 100%"
          >
            <div
              :style="{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: errors[0] ? '30px' : '',
              }"
            >
              <div style="width: 100%">
                <vue-autosuggest
                  style="
                    min-width: 300px;
                    background-color: transparent !important;
                  "
                  v-model="queryClassification"
                  :suggestions="subject.searchClassification.classifications"
                  :input-props="inputClassificationProps"
                  :section-configs="classificationConfigs"
                  @input="searchClassifications"
                  :loading="subject.searchClassification.loading"
                  autocomplete="off"
                  :class="{ disabled: !subject.companiesSelected }"
                  :style="{ backgroundColor: 'transparent' }"
                >
                  <template slot-scope="{ suggestion }">
                    {{ suggestion.item.name }}
                  </template>
                </vue-autosuggest>
                <small class="text-danger position-absolute">{{
                  errors[0]
                }}</small>
              </div>
            </div>
          </validation-provider>
        </b-col>

        <b-col class="mb-25">
          <span>Professor *</span>
          <validation-provider
            #default="{ errors }"
            name="Professor"
            rules="required"
          >
            <v-select
              style="min-width: 300px"
              v-model="subject.teacherSelected"
              :options="
                mountTeacherOptions(
                  subject.searchTeacher.teachers,
                  subject.searchTeacher.others
                )
              "
              autocomplete="off"
              :clearable="false"
              :filterable="false"
              @search="searchTeacher"
              :loading="subject.searchTeacher.loading"
              :state="errors.length > 0 ? false : null"
              :selectable="(option) => option.active"
            >
              <template #option="option">
                <div :class="{ 'text-center': option.value < 0 }">
                  <feather-icon
                    v-if="option.value < 0"
                    icon="SearchIcon"
                    size="16"
                  />
                  <b-badge
                    class="mr-25"
                    v-if="option.value > 0 && !option.active"
                    variant="danger"
                  >
                    <span>inativo</span>
                  </b-badge>
                  {{ option.label }}
                </div>
              </template>
              <template #selected-option="{ label }">
                <div
                  class="d-inline-block text-truncate"
                  style="max-width: 340px"
                >
                  {{ label }}
                </div>
              </template>
            </v-select>
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          </validation-provider>
        </b-col>

        <b-col class="mb-25">
          <span>Início *</span>
          <validation-provider name="Início" rules="required">
            <b-input-group style="min-width: 250px">
              <b-input-group-prepend>
                <b-button variant="outline-info" @click="setTimeline(true)">
                  <feather-icon
                    style="
                      float: right;
                      position: absolute;
                      right: 12px;
                      top: 10px;
                    "
                    icon="EyeIcon"
                    size="16"
                  />
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                style="max-width: 91%"
                v-model="subject.start"
                placeholder="HH:mm:ss"
                v-mask="$utils.masked.time"
                :state="
                  validNoteTime(subject.start, subject.end) ? null : false
                "
              />
              <b-input-group-append>
                <b-button variant="outline-info" @click="setStartSubject()">
                  <feather-icon
                    style="
                      float: left;
                      position: absolute;
                      left: 12px;
                      top: 10px;
                    "
                    icon="ScissorsIcon"
                    size="16"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </validation-provider>
        </b-col>

        <b-col class="mb-25">
          <span>Fim *</span>
          <validation-provider name="Fim" rules="required">
            <b-input-group style="min-width: 250px">
              <b-input-group-prepend>
                <b-button variant="outline-info" @click="setTimeline(false)">
                  <feather-icon
                    style="
                      float: right;
                      position: absolute;
                      right: 12px;
                      top: 10px;
                    "
                    icon="EyeIcon"
                    size="16"
                  />
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                style="max-width: 91%"
                v-model="subject.end"
                placeholder="HH:mm:ss"
                v-mask="$utils.masked.time"
                :state="
                  validNoteTime(subject.start, subject.end) ? null : false
                "
              />
              <b-input-group-append>
                <b-button variant="outline-info" @click="setEndSubject()">
                  <feather-icon
                    style="
                      float: left;
                      position: absolute;
                      left: 12px;
                      top: 10px;
                    "
                    icon="ScissorsIcon"
                    size="16"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </validation-provider>
        </b-col>

        <template
          v-if="subject.productSelected && subject.productSelected.value == 2"
        >
          <b-col class="mb-25">
            <span>Instituição</span>
            <v-select
              style="min-width: 300px"
              v-model="subject.institutionSelected"
              :options="subject.searchInstitute.institutions"
              :clearable="false"
              autocomplete="off"
              :filterable="false"
              @search="searchInstitute"
              :loading="subject.searchInstitute.loading"
            >
              <template #selected-option="{ label }">
                <div
                  class="d-inline-block text-truncate"
                  style="max-width: 340px"
                >
                  {{ label }}
                </div>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em style="opacity: 0.5" v-else>Digite a Instituição</em>
              </template>
            </v-select>
            <feather-icon
              v-if="subject.institutionSelected"
              style="float: left; position: absolute; right: 20px; top: 32px"
              class="cursor-pointer"
              icon="XIcon"
              size="16"
              @click="
                subject.institutionSelected = !subject.institutionSelected
              "
            />
          </b-col>

          <b-col>
            <span>Ano</span>
            <v-select
              style="min-width: 170px"
              v-model="subject.question_year"
              :options="years"
              autocomplete="on"
              :clearable="true"
            />
          </b-col>

          <b-col>
            <b-button
              v-if="subject.question_identified"
              style="margin-top: 20px; font-size: 10pt"
              block
              variant="outline-info"
              @click="
                subject.question_identified = !subject.question_identified
              "
            >
              Informar Questão
            </b-button>
            <div v-else>
              <span>ID da Questão *</span>
              <b-form-input
                v-model="subject.name"
                placeholder="0"
                type="number"
                style="min-width: 200px; padding-right: 25px"
              />
              <feather-icon
                style="float: left; position: absolute; right: 20px; top: 32px"
                class="cursor-pointer"
                icon="XIcon"
                size="16"
                @click="
                  subject.question_identified = !subject.question_identified
                "
              />
            </div>
          </b-col>
        </template>

        <b-col
          v-if="
            subject.productSelected &&
            subject.productSelected.value != 2 &&
            subject.type_media == 0
          "
        >
          <span>Pág. Início</span>
          <b-form-input
            :disabled="!hasPDF"
            style="min-width: 90px"
            type="number"
            v-model="subject.page_start"
            placeholder="0"
          />
        </b-col>
        <b-col
          v-if="
            subject.productSelected &&
            subject.productSelected.value != 2 &&
            subject.type_media == 0
          "
        >
          <span>Pág. Fim</span>
          <b-form-input
            :disabled="!hasPDF"
            style="min-width: 90px"
            type="number"
            v-model="subject.page_end"
            placeholder="0"
          />
        </b-col>

        <template
          v-if="!subject.productSelected || subject.productSelected.value != 2"
        >
          <b-col class="mb-25">
            <validation-provider
              #default="{ errors }"
              name="Assunto"
              rules="required"
            >
              <span>Assunto *</span>
              <b-form-input
                style="min-width: 500px"
                v-model="subject.name"
                placeholder="Informe o Assunto"
                maxlength="255"
                :state="errors.length > 0 ? false : null"
              />
              <span
                class="d-flex justify-content-end"
                :style="totalChar < 255 ? '' : 'color: red;'"
              >{{ totalChar }}/255
              </span>
            </validation-provider>
          </b-col>
        </template>

        <b-col cols="12">
          <div class="mt-1 d-flex justify-content-end">
            <div class="mr-1">
              <b-button
                variant="outline-info"
                :disabled="Number(this.subject.id) ? false : true"
                v-b-tooltip.hover.top="'Pendências'"
                @click="modalPendencies()"
              >
                <feather-icon icon="AlertOctagonIcon" size="16" />
              </b-button>
            </div>

            <div class="mr-1">
              <b-button
                variant="outline-info"
                :disabled="
                  !(validNoteTime(subject.start, subject.end) && subject.id > 0)
                "
                v-b-tooltip.hover.top="'Minutagem'"
                @click="modalMinutagem()"
              >
                <feather-icon icon="ClockIcon" size="16" />
              </b-button>
            </div>

            <div class="mr-1">
              <b-button
                variant="outline-info"
                v-b-tooltip.hover.top="'Clonar'"
                @click="() => $emit('cloneSubject')"
              >
                <feather-icon icon="CopyIcon" size="16" />
              </b-button>
            </div>

            <div>
              <b-button
                variant="outline-danger"
                v-b-tooltip.hover.top="'Apagar'"
                @click="() => $emit('deleteSubject')"
              >
                <feather-icon icon="Trash2Icon" size="16" />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <ModalMinutagem
      :items="notes"
      :subjectActive="subject"
      :show="showNotes"
      @getMinutagem="modalMinutagem"
      @obsHidden="() => (showNotes = false)"
    />
    <b-modal
      ref="modal-pendencies"
      title="Pendências Associadas"
      hide-footer
      scrollable
      size="lg"
      no-close-on-esc
    >
      <SubjectPendencies :subjectActive="subject" />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { mapState, mapMutations } from "vuex";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import _companieService from "@/services/company-service";
import _productService from "@/services/audiovisual/products-service";
import _minutagemService from "@/services/audiovisual/minutagem-service";
import _institutionsService from "@/services/audiovisual/institutions-issues";
import _projectService from "@/services/projects-service";
import _teachersService from "@/services/teachers-service";
import _disciplineService from "@/services/discipline-service";
import _mediaSubjectVideoService from "@/services/audiovisual/media-subject-video-service";
import _mediaSubjectAudioService from "@/services/audiovisual/media-subject-audio-service";
import ModalMinutagem from "./ModalMinutagem.vue";
import _mediaSubjectPendingService from "@/services/audiovisual/media-subject-pending-service";
import SubjectPendencies from "./SubjectPendencies.vue";

import {
  BModal,
  BListGroup,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BRow,
  BCol,
  BIcon,
  VBTooltip,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { _classificationService } from "@/services/classification-service";

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BRow,
    BCol,
    BIcon,
    VBTooltip,
    BSpinner,
    ModalMinutagem,
    BBadge,
    SubjectPendencies,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    hasPDF: {
      type: Boolean,
      default: false,
    },
    index: {
      required: true,
    },
    pdf: {
      required: false,
    },
    mediaDuration: {
      type: Number,
    },
    activeAutoSave: {
      type: Boolean,
      required: true,
    },
    appointmentId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState({
      currentMediaId: (state) => state.toolbar.videoPlayer.videoId,
      currentMediaElement: (state) => state.toolbar.videoPlayer.videoElement,
    }),
    totalChar() {
      return this.subject.name?.length || 0
    },
  },
  watch: {
    loading() {
      this.validateSync();
    },
    noSync() {
      this.validateSync();
    },
    "subject.id"() {
      this.validateSync();
    },
    queryClassification(value, oldValue) {
      const selected = this.subject.classificationSelected.label;
      const clear = {
        value: null,
        label: "",
      };
      if (value == null || value == "" || value === selected) {
        this.newClassification.available = false;
      } else {
        this.newClassification.available = true;
      }

      if (oldValue == selected) {
        if (value !== selected) {
          this.subject.classificationSelected = clear;
        }
      }
      if (oldValue !== null && (value == null || value == "")) {
        this.subject.classificationSelected = clear;
      }
    },
  },
  data() {
    return {
      userData: {
        id_external:
          JSON.parse(localStorage.getItem("userData"))?.id_external ?? null,
      },
      loading: false,
      years: this.questionYear(),
      companies: [],
      products: [],
      allProducts: [],
      jobTypes: [],
      notes: [],
      showNotes: false,
      subject: {},
      timer: null,
      noSync: false,
      pendencies: [],
      newClassification: {
        name: null,
        available: false,
      },
      queryClassification: null,
      isloadingModal: false,
      inputClassificationProps: {
        id: "autoclassifications__input_ajax",
        placeholder: "Digite o nome da Classificação",
        class: "form-control",
        name: "ajax",
      },
      classificationConfigs: {
        classifications: {
          //limit: 20,
          label: "Classificação",
          onSelected: (selected) => {
            this.subject.classificationSelected = {
              value: selected.item.id,
              label: selected.item.name,
            };
            this.queryClassification =
              this.subject.classificationSelected.label;
          },
        },
      },
    };
  },
  created() {
    this.initComponent();
    this.getCompanies();
  },
  methods: {
    ...mapMutations({
      SUBJECT_VALIDATE: "toolbar/videoPlayer/SUBJECT_VALIDATE",
    }),
    validateSync() {
      let saved = Number(this.subject.id) ? false : true;
      if (saved || this.loading || this.noSync) {
        this.SUBJECT_VALIDATE({ index: this.index, validate: true });
      } else {
        this.SUBJECT_VALIDATE({ index: this.index, validate: false });
      }
    },
    initComponent() {
      this.subject = this.item;
      if (
        this.subject.companiesSelected?.value ==
        process.env.VUE_APP_APP_TECH_COMPANY
      ) {
        // remove a empresa e projeto default
        this.subject.companiesSelected = null;
        this.subject.projectSelected = null;
      }
      if (!this.subject.teacherSelected.active) {
        this.subject.teacherSelected = null;
      }
      if (!this.subject.classificationSelected) {
        this.subject.classificationSelected = {
          value: null,
          label: null,
        };
      }
      this.queryClassification = this.subject.classificationSelected.label;
      this.$watch(
        "subject",
        (newVal, oldVal) => {
          this.saveSubject(newVal, oldVal);
        },
        { deep: true }
      );
      this.changeCompany(this.subject);
      this.changeProject(this.subject, false);
    },
    saveSubject(newVal, _oldVal) {
      if (!this.activeAutoSave) return;
      this.$refs.subject.validate().then((success) => {
        if (success && this.validNoteTime(newVal.start, newVal.end)) {
          this.loading = true;
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            let temp = newVal;
            let isupdate = Number(temp.id) ? true : false;

            if (temp.productSelected && temp.productSelected.value === 2) {
              if (temp.question_identified) {
                temp.name = "questao_nao_identificada";
              } else {
                if (temp.name.toString().length < 4) {
                  this.$utils.toastError(
                    "Notificação",
                    "O ID da questão precisa ter no mínimo 4 caracteres"
                  );
                  this.noSync = true;
                  this.loading = false;
                  return;
                }
              }
            } else {
              if (temp.name.toString().length > 255) {
                this.$utils.toastError(
                  "Notificação",
                  "O assunto precisa ter no máximo 255 caracteres"
                );
                this.noSync = true;
                this.loading = false;
                return;
              }
            }

            if (temp.productSelected && temp.productSelected.value !== 2) {
              temp.institutionSelected = null;
              temp.question_year = null;
            }
            if (temp.classificationSelected.value) {
              this.queryClassification = temp.classificationSelected.label;
              this.classificationSelected = temp.classificationSelected;
            }
            if (temp.type_media == 0) {
              //video
              let payload = {
                data: {
                  //atributos comuns em audio e vídeo
                  id: isupdate ? temp.id : 0,
                  recording_media_file_id: temp.recording_media_file_id,
                  start_time: temp.start,
                  end_time: temp.end,
                  subject: temp.name,
                  teacher_id: temp.teacherSelected
                    ? temp.teacherSelected.value
                    : null,
                  product_id: temp.productSelected
                    ? temp.productSelected.value
                    : null,
                  discipline_id: temp.disciplineSelected
                    ? temp.disciplineSelected.value
                    : null,
                  classification_platform_id: temp.classificationSelected
                    ? temp.classificationSelected.value
                    : null,
                  destiny_project_id: temp.projectSelected
                    ? temp.projectSelected.value
                    : null,
                  order: temp.order,

                  // atributos específicos de vídeo
                  institution_issues_id: temp.institutionSelected
                    ? temp.institutionSelected.value
                    : null,
                  question_year: temp.question_year,
                  page_start: temp.page_start
                    ? temp.page_start.toString()
                    : null,
                  page_end: temp.page_end ? temp.page_end.toString() : null,
                  question_identified: temp.question_identified,
                  type_media: temp.type_media,
                  media_file_name_file: temp.media_file_name_file, // s3key PDF do material
                  presentation_file_s3_key: temp.presentation_file_s3_key, // s3key PDF do assunto
                  company_id: this.subject.companiesSelected.value,
                },
              };
              if (payload.data.id > 0) {
                _mediaSubjectVideoService
                  .update(payload)
                  .then((res) => {
                    temp.id = res.content.id;
                    this.noSync = false;
                    temp.presentation_file_s3_key =
                      res.content.presentation_file_s3_key;
                  })
                  .catch((error) => {
                    this.noSync = true;
                    this.$utils.toastError("Notificação", error.toString());
                  })
                  .finally(() => (this.loading = false));
              } else {
                _mediaSubjectVideoService
                  .store(payload)
                  .then((res) => {
                    temp.id = res.content.id;
                    this.noSync = false;
                    temp.presentation_file_s3_key =
                      res.content.presentation_file_s3_key;
                  })
                  .catch((error) => {
                    this.noSync = true;
                    this.$utils.toastError("Notificação", error.toString());
                  })
                  .finally(() => (this.loading = false));
              }
            }
            if (temp.type_media == 1) {
              //audio
              let payload = {
                data: {
                  //atributos comuns em audio e vídeo
                  id: isupdate ? temp.id : 0,
                  recording_media_file_id: temp.recording_media_file_id,
                  start_time: temp.start,
                  end_time: temp.end,
                  subject: temp.name,
                  teacher_id: temp.teacherSelected
                    ? temp.teacherSelected.value
                    : null,
                  product_id: temp.productSelected
                    ? temp.productSelected.value
                    : null,
                  discipline_id: temp.disciplineSelected
                    ? temp.disciplineSelected.value
                    : null,
                  classification_platform_id: temp.classificationSelected
                    ? temp.classificationSelected.value
                    : null,
                  destiny_project_id: temp.projectSelected
                    ? temp.projectSelected.value
                    : null,
                  order: temp.order,
                  company_id: this.subject.companiesSelected.value,
                },
              };

              if (payload.data.id > 0) {
                _mediaSubjectAudioService
                  .update(payload)
                  .then((res) => {
                    temp.id = res.content.id;
                    this.noSync = false;
                  })
                  .catch((error) => {
                    this.noSync = true;
                    this.$utils.toastError("Notificação", error.toString());
                  })
                  .finally(() => (this.loading = false));
              } else {
                _mediaSubjectAudioService
                  .store(payload)
                  .then((res) => {
                    temp.id = res.content.id;
                    this.noSync = false;
                  })
                  .catch((error) => {
                    this.noSync = true;
                    this.$utils.toastError("Notificação", error.toString());
                  })
                  .finally(() => (this.loading = false));
              }
            }
          }, 5000);
        } else {
          this.noSync = true;
        }
      });
    },
    searchDiscipline(_search) {
      if (_search && _search.length >= 3) {
        clearTimeout(this.subject.searchDiscipline.timer);
        this.subject.searchDiscipline.timer = setTimeout(() => {
          this.subject.searchDiscipline.loading = true;
          _disciplineService
            .autoComplete(_search)
            .then((res) => {
              if (res.content && res.content.length > 0) {
                this.subject.searchDiscipline.disciplines = [];
                res.content.forEach((element) => {
                  this.subject.searchDiscipline.disciplines.push({
                    value: element.id,
                    label: element.name,
                  });
                });
              }
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.subject.searchDiscipline.loading = false));
        }, 1500);
      }
    },
    searchClassifications(_search = this.queryClassification) {
      if (_search && _search.length >= 3) {
        clearTimeout(this.subject.searchClassification.timer);
        this.subject.searchClassification.timer = setTimeout(() => {
          this.subject.searchClassification.loading = true;
          _classificationService
            .platformAutoComplete(this.subject.companiesSelected.value, _search)
            .then((res) => {
              if (res.content && res.content.length > 0) {
                this.subject.searchClassification.classifications = [
                  { name: "classifications", data: res.content },
                ];
              }
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.subject.searchClassification.loading = false));
        }, 1500);
      }
    },
    searchTeacher(_search) {
      if (this.subject.searchTeacher.teachers.some((s) => !s.active)) {
        if (_search && _search.length >= 3) {
          clearTimeout(this.subject.searchTeacher.timer);
          this.subject.searchTeacher.timer = setTimeout(() => {
            this.subject.searchTeacher.loading = true;
            _teachersService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  const options = [];
                  res.content.forEach((element) => {
                    options.push({
                      value: element.id,
                      label: element.name,
                      active: element.active,
                    });
                  });
                  this.subject.searchTeacher.others = [...new Set(options)];
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.subject.searchTeacher.loading = false));
          }, 1500);
        }
      }
    },
    mountTeacherOptions(fixed, others) {
      const options = [...fixed];
      if (Array.isArray(others) && others.length > 0) {
        options.push({
          value: -1,
          label: "outros professores:",
          active: false,
        });
      }
      options.push(...others);
      return options;
    },
    searchProject(_search) {
      if (
        this.subject.companiesSelected &&
        this.subject.companiesSelected.value > 0
      ) {
        if (_search && _search.length >= 3) {
          clearTimeout(this.subject.searchProject.timer);
          this.subject.searchProject.timer = setTimeout(() => {
            this.subject.searchProject.loading = true;
            _projectService
              .autoCompleteByCompany(
                _search,
                this.subject.companiesSelected.value
              )
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.subject.searchProject.projects = [];
                  res.content.forEach((element) => {
                    this.subject.searchProject.projects.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.subject.searchProject.loading = false));
          }, 1500);
        }
      } else {
        this.$utils.toastError(
          "Notificação",
          "Selecione a empresa para pesquisar o projeto!"
        );
      }
    },
    searchInstitute(_search) {
      if (_search && _search.length >= 3) {
        if (!this.subject.searchInstitute.loading) {
          this.subject.searchInstitute.loading = true;
          setTimeout(() => {
            _institutionsService
              .find(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.subject.searchInstitute.institutions = [];
                  res.content.forEach((element) => {
                    this.subject.searchInstitute.institutions.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.subject.searchInstitute.loading = false));
          }, 1500);
        }
      }
    },
    getCompanies() {
      _companieService
        .showAll()
        .then((res) => {
          // remove a empresa default da lista de escolha
          var filteredCompanies = res.content.filter(
            (c) => c.id != process.env.VUE_APP_APP_TECH_COMPANY
          );
          this.companies = this.$utils.populardropdown(
            filteredCompanies,
            "name",
            "id"
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getProducts(project, type_media) {
      this.loading = true;
      _productService
        .showProductsAllByProject(project, this.appointmentId)
        .then((res) => {
          if (this.appointmentId) {
            this.products = this.$utils.populardropdown(
              res.content.filter((f) => f.type_media == type_media),
              "name",
              "id"
            );
          } else {
            this.allProducts = res.content;
            this.jobTypes = [];
            for (let i = 0; i < res.content.length; i++) {
              if (res.content[i].type_media == type_media) {
                // se ja existe no array ignorar
                if (this.jobTypes.some((e) => e.value === res.content[i].jobType.id)) {
                  continue;
                }
                this.jobTypes.push({
                  value: res.content[i].jobType.id,
                  label: res.content[i].jobType.name,
                });
              }
            }

            if (this.jobTypes.length == 1) {
              this.subject.jobTypeSelected = this.jobTypes[0];
              this.changeJobType(this.jobTypes[0], false);
            } else if (this.subject.productSelected) {
              const product = this.allProducts.find(
                (f) => f.id == this.subject.productSelected.value
              );
              this.subject.jobTypeSelected = {
                value: product.jobType.id,
                label: product.jobType.name,
              };
              this.changeJobType(this.subject.jobTypeSelected, false);
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.loading = false;
        });
    },
    changeJobType(jobType, clear) {
      this.products = this.$utils.populardropdown(
        this.allProducts.filter((f) => f.jobType.id == jobType.value && f.type_media == this.item.type_media),
        "name",
        "id"
      );

      if (clear) {
        this.subject.productSelected = null;
      }
    },
    questionYear() {
      let result = [];
      let year = new Date().getFullYear() + 1;
      for (let i = year - 30; i <= year; i++) {
        result.unshift(i);
      }
      return result;
    },
    setTimeline(_isStart) {
      let media = document.getElementById(`media-${this.currentMediaId}`);
      let splitedArray = [];

      if (_isStart) {
        if (!this.subject.start || this.subject.start.length < 8) return;
        splitedArray = this.subject.start.split(":");
      } else {
        if (!this.subject.end || this.subject.end.length < 8) return;
        splitedArray = this.subject.end.split(":");
      }

      let hours = parseInt(splitedArray[0] * 3600);
      let minutes = parseInt(splitedArray[1] * 60);
      let seconds = parseInt(splitedArray[2]);

      media.currentTime = hours + minutes + seconds;

      this.currentMediaElement.pip.currentTime =
        media.currentTime > Math.floor(media.duration)
          ? Math.floor(media.currentTime)
          : media.currentTime;
    },
    setStartSubject() {
      var media = document.getElementById(`media-${this.currentMediaId}`);
      let hours = Math.floor(media.currentTime / 3600);
      let minutes = Math.floor((media.currentTime - hours * 3600) / 60);
      let seconds = Math.floor(media.currentTime % 60);

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      this.subject.start = hours + ":" + minutes + ":" + seconds;
    },
    setEndSubject() {
      var media = document.getElementById(`media-${this.currentMediaId}`);
      let hours = Math.floor(media.currentTime / 3600);
      let minutes = Math.floor((media.currentTime - hours * 3600) / 60);
      let seconds = Math.floor(media.currentTime % 60);

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      this.subject.end = hours + ":" + minutes + ":" + seconds;
    },
    modalMinutagem() {
      _minutagemService
        .showByMediaSubject(this.subject.id)
        .then((res) => {
          if (res.content) {
            this.notes = res.content;
            this.showNotes = true;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    modalPendencies() {
      this.$refs["modal-pendencies"].show();
    },
    timeFormatter(time) {
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time - hours * 3600) / 60);
      let seconds = Math.floor(time % 60);

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      return hours + ":" + minutes + ":" + seconds;
    },
    validNoteTime(startTime, endTime) {
      // calcula a diferença de milesegundos usando o mesmo dia
      if (startTime.length < 8 || endTime.length < 8) return false;
      let subject_time = new Date(
        "December 25, 1995 " + this.timeFormatter(this.mediaDuration)
      );
      let start = new Date("December 25, 1995 " + startTime);
      let end = new Date("December 25, 1995 " + endTime);
      if (start >= end || end <= start || end > subject_time) return false;
      return true;
    },
    changeCompany(subject, clear) {
      if (subject.companiesSelected && subject.companiesSelected.value > 0) {
        if (clear) {
          subject.projectSelected = null;
          subject.productSelected = null;
          subject.classificationSelected = {
            value: null,
            label: null,
          };
          subject.searchClassification.loading = false;
          this.queryClassification = null;
          subject.searchClassification.classifications = [];
        }
      }
    },
    changeProject(subject, clear) {
      if (!subject.projectSelected || !subject.projectSelected.value || subject.projectSelected.value <= 0) {
        return;
      }

      if (clear) {
        subject.productSelected = null;
        subject.jobTypeSelected = null;
      }

      this.getProducts(subject.projectSelected.value, this.item.type_media);
    },
  },
};
</script>

<style scoped>
.draggable-body {
  padding: 0.75rem 1.25rem;
  cursor: auto;
}
.sync-icon {
  position: absolute;
  top: 0px;
  right: 3px;
}
.disabled {
  pointer-events: none;
  opacity: 0.8;
}
</style>
