import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  filter: (recording_media_pending_id,teacher_id,completed) => {
    let _parans = `?page=0`;
      if (recording_media_pending_id)
         _parans += `&data.recording_media_pending_id=${recording_media_pending_id}`;
      if (teacher_id)
        _parans += `&data.teacher_id=${teacher_id}`;
      if (completed != null)
        _parans += `&data.completed=${completed}`;
    return _http.get(`/api/audio-visual/recording-media-pending-subject/filter${_parans}`)
  },
  findById: (id) => {
    return _http.get(`/api/audio-visual/recording-media-pending-subject/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/audio-visual/recording-media-pending-subject', payload)
  },
  update: (payload) => {
    return _http.put('/api/audio-visual/recording-media-pending-subject', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/recording-media-pending-subject/${id}`)
  },
}