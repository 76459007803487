<template>
  <!-- FORM WIZARD -->
  <form-wizard ref="wizard" :title="null" :subtitle="null" color="#1890ff">
    <!-- BOTÕES DE RODAPÉ -->
    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left mt-2">
        <b-button
          v-if="props.activeTabIndex > 0"
          @click="props.prevTab()"
          variant="outline-primary"
        >
          <feather-icon icon="ChevronLeftIcon" class="mr-50" />
          <span class="align-middle">Voltar</span>
        </b-button>
      </div>
      <div class="wizard-footer-right mt-2">
        <b-button
          v-if="!props.isLastStep"
          @click="props.nextTab()"
          variant="info"
          style="min-width: 125px"
          :disabled="isLoading"
        >
          <div v-if="!isLoading">
            <span class="align-middle">Salvar e Avançar</span>
            <feather-icon icon="ChevronRightIcon" class="ml-50" />
          </div>
          <b-spinner v-else small />
        </b-button>

        <b-button
          v-else
          @click="formSubmitted"
          variant="info"
          style="min-width: 125px"
          :disabled="isLoading"
        >
          <div v-if="!isLoading">
            <span class="align-middle">{{
              description && description.length > 0
                ? "Salvar e Concluir"
                : "Concluir"
            }}</span>
            <feather-icon icon="ChevronRightIcon" class="ml-50" />
          </div>
          <b-spinner v-else small />
        </b-button>
      </div>
    </template>
    <!-- FIM BOTÕES DE RODAPÉ -->

    <!-- STEP UPLOAD DOS VÍDEOS -->
    <tab-content
      title="Upload do Material"
      icon="feather icon-upload"
      :before-change="onHandleUpload"
    >
      <StepUpload
        :loading="false"
        :items="recordingMediaFiles"
        @changeItems="updateMediaFiles"
      />
    </tab-content>

    <!-- STEP DE ASSUNTOS -->
    <tab-content
      title="Informações e Assuntos"
      icon="feather icon-list"
      :before-change="validateSubject"
      class="mb-2"
    >
      <StepSubjects
        v-if="recording && recording.id > 0"
        :recording_media_id="Number(recording.id)"
        :items="recordingMediaFiles.filter((f) => !f.excluded)"
        :appointment="appointment"
        :key="randomKey"
        :teacher="teacher"
      />
    </tab-content>

    <!-- STEP DE OBSERVAÇÕES -->
    <tab-content title="Observações" icon="feather icon-file-text">
      <b-card
        title="Observações"
        sub-title="Adicione aqui observações pertinentes aos arquivos"
      >
        <quill-editor
          style="height: 300px; padding-bottom: 45px"
          :options="editorOption"
          v-model="description"
        >
        </quill-editor>
        <span
          class="d-flex justify-content-end"
          :style="totalChar <= 3000 ? '' : 'color: red;'"
        >
          {{ totalChar }}/3000
        </span>
      </b-card>
    </tab-content>
  </form-wizard>
</template>

<script>
import { BButton, BCard, BFormGroup } from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import { mapMutations, mapState } from "vuex";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import StepUpload from "./components/StepUpload.vue";
import StepSubjects from "./components/StepSubjects.vue";
import _mediaFilesService from "@/services/audiovisual/recording-media-files";
import _recordingMediaService from "@/services/audiovisual/recording-media";

export default {
  props: {
    isOperator: {
      type: Boolean,
      required: true,
    },
    recording: {
      type: Object,
      required: true,
    },
    appointment: {
      type: Object,
      required: false,
    },
    teacher: {
      type: Object,
      required: false,
    },
    description_origin: {
      type: String,
    },
  },
  components: {
    FormWizard,
    TabContent,
    BButton,
    StepUpload,
    StepSubjects,
    BCard,
    BFormGroup,
  },
  computed: {
    ...mapState({
      subjectError: (state) => state.toolbar.videoPlayer.subjectError,
    }),
    totalChar() {
      return this.description ? this.description.length : 0;
    },
    onUpdate() {
      return false;
    }
  },
  watch: {
    recording: {
      handler(_new, _old) {
        this.getMediaFiles();
      },
      deep: true,
    },
    description_origin(newValue, _oldValue) {
      this.description = newValue;
    }
  },
  data() {
    return {
      isLoading: false,
      recordingMediaFiles: [],
      randomKey: 1,
      isSubmitting: false,
      year: new Date().getFullYear(),
      teachers: [],
      isLoadingTeacher: false,
      teacherSelected: null,
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }],
          ],
        },
      },
      description: "",
    };
  },
  mounted() {
    this.getMediaFiles();
  },
  destroyed() {
    this.UPDATE_CURRENT_TOOLBAR("none");
  },
  methods: {
    ...mapMutations({
      UPDATE_CURRENT_TOOLBAR: "toolbar/UPDATE_CURRENT_TOOLBAR",
    }),
    getMediaFiles() {
      if (this.recording.mediaFiles.length > 0) {
        let lst = this.recording.mediaFiles.map((record) => {
          return {
            name: record.s3_key,
            duration: record.media_time,
            type_media: record.type_media,
            presentation_file_s3_key: record.presentation_file_s3_key,
            original_filename: record.original_filename,
            excluded: record.recordingMediaFiles.filter((rec) => {
              return rec.recording_media_id == this.recording.id;
            })[0].excluded,
            recordingMediaFilesID: record.recordingMediaFiles.filter((rec) => {
              return rec.recording_media_id == this.recording.id;
            })[0].id,
            media_file_id: record.recordingMediaFiles.filter((rec) => {
              return rec.recording_media_id == this.recording.id;
            })[0].media_file_id,
            media_file_order: record.recordingMediaFiles.filter((rec) => {
              return rec.recording_media_id == this.recording.id;
            })[0].media_file_order,
            progress: 100,
            complete: true,
            uploaded: true,
            loading: false,
            //Utilizado no Subject
            pdf: {},
            preview: null,
            timeline: {
              subjects: [],
              duration: {
                minutes: "",
                seconds: "",
                hours: "",
              },
            },
            pip: {
              duration: 0,
              currentTime: 0,
              currentVol: 0.5,
              currentVolIcon: "Volume1Icon",
            },
          };
        });
        this.randomKey = Math.floor(Math.random() * 100); // reconstroi o componente
        this.recordingMediaFiles = this.orderList(lst);
      }
    },
    updateMediaFiles(items) {
      this.recordingMediaFiles = items;
    },
    async onHandleUpload() {
      // validações
      if (this.recordingMediaFiles && this.recordingMediaFiles.length == 0) {
        this.$utils.toastWarning(
          "Arquivo Pendente",
          "Nenhum material foi enviado"
        );
        return false;
      } else if (this.recordingMediaFiles.some((f) => !f.complete)) {
        this.$utils.toastWarning(
          "Envio em Progresso",
          "Pedimos, por favor, que aguarde e tente novamente"
        );
        return false;
      }

      return await this.addNewMediaFiles();
    },
    async addNewMediaFiles() {
      this.isLoading = true;
      let payload = this.recordingMediaFiles
        .filter((file) => !file.uploaded)
        .map((file) => {
          return {
            s3_key: file.key,
            recordingMediaFiles: [{ recording_media_id: this.recording.id }],
            original_filename: file.name,
            type_media: file.type.includes("audio") ? 1 : 0
          };
        });

      const result = await _mediaFilesService
        .create(payload)
        .then((_res) => {
          this.UPDATE_CURRENT_TOOLBAR("none");
          return true;
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
          return false;
        })
        .finally(() => (this.isLoading = false));

      this.$emit("getRecords");

      return result;
    },
    async validateSubject() {
      if (this.subjectError) {
        this.$utils.toastWarning(
          "Assuntos não sincronizados",
          "Pedimos, por favor, que preencha todos os campos obrigatórios de cada assunto"
        );
        return false;
      } else {
        return true;
      }
    },
    formSubmitted() {
      if (
        (this.description_origin && this.description_origin.length > 0) ||
        (this.description && this.description.length > 0)
      ) {
        this.isLoading = true;
        const payload = [
          {
            path: "/description",
            op: "replace",
            value: this.description,
          },
        ];
        _recordingMediaService
          .updateDescription(this.recording.id, payload)
          .then((_res) => {
            this.$router.go(-1);
          })
          .catch((error) => {
            this.$utils.toastError("Notificação", error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$router.go(-1);
      }
    },
    orderList(lst) {
      lst.sort((a, b) => {
        if (a.media_file_order < b.media_file_order) return -1;
        if (a.media_file_order > b.media_file_order) return 1;
        return 0;
      });

      return lst;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
