<template>
  <viewcard--c :title="recordingID ? 'Editar Gravação' : 'Nova Gravação'" :btsave="null" :btdelete="null"
    :busy="isLoading" :btback="btback" @clicked-go="goBack">
    <b-modal ref="modal-add-ocorrencias" title="Adicionar Ocorrência" hide-footer centered no-close-on-backdrop
      v-model="isOpenModal" @hide="onOccurrenceHideModal">
      <div class="row">
        <div class="col-12">
          <occurence-custom @submitted="onOccurrenceSubmit" :isNewRecording="true" />
        </div>
      </div>
    </b-modal>
    <MaterialUpload v-if="recording" :recording="recording" :isOperator="false" :appointment="appointment"
      @getRecords="getRecord" :teacher="teacher" :description_origin="description" />
  </viewcard--c>
</template>
<script>
import MaterialUpload from "./components/material-upload/MaterialUpload.vue";
import recordingOccurence from "./components/Occurrence.vue";
import _recordingService from "@/services/audiovisual/recording-media";
import _mediaFilesService from "@/services/audiovisual/recording-media-files";
import _appointmentService from "@/services/appointment-service";

import {
  BFormFile,
  BEmbed,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BModal,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import { oc } from "date-fns/locale";
export default {
  components: {
    BFormFile,
    BEmbed,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    MaterialUpload,
    BModal,
    BOverlay,
    BBadge,
    "occurence-custom": recordingOccurence,
  },
  data() {
    return {
      isOpenModal: false,
      is_clone: false,
      description: "",
      isLoading: false,
      recordingID: this.$route.params.id || null,
      appointmentID: null,
      recording: null,
      appointment: null,
      teacherID: this.$route.params.teacher || null,
      btback: {
        click: true,
        permission: `permission.audiovisual.demands.upload.midia.view`,
      },
      teacher: null,
    };
  },
  created() {
    this.getRecord();
    this.getRecordDetails();
    if (!this.recordingID)
      this.isOpenModal = true;
  },
  methods: {
    onOccurrenceHideModal(event) {
      if(event?.trigger){
        this.$router.push({
          path: "/audio-visual/upload",
        });
      }
    },
    onOccurrenceSubmit(_form) {
      this.createRecording(_form);
    },
    getRecord() {
      if (this.recordingID) {
        this.isLoading = true;
        _mediaFilesService
          .findAllByRecording(this.recordingID)
          .then((res) => {
            if (res.content) {
              this.recording = {
                id: this.recordingID,
                mediaFiles: res.content,
              };
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isLoading = false));
      }
    },
    getRecordDetails() {
      if (this.recordingID) {
        this.loading = true;
        _recordingService
          .getDetails(this.recordingID)
          .then((res) => {
            if (res && res.content) {
              if (res.content.excluded || res.content.finalized) {
                let message_error = res.content.excluded
                  ? "Gravação já excluída."
                  : "Gravação já finalizada.";
                this.$utils.toastError("Notificação", message_error);
                setTimeout(() => {
                  this.$router.push({
                    path: "/audio-visual/upload",
                  });
                }, 3000);
              }

              this.description = res.content.description;

              if (res.content.recording_media_parent_id) {
                //é clone
                this.loading = true;
                _recordingService
                  .getDetails(res.content.recording_media_parent_id)
                  .then((clone) => {
                    if (clone.content && clone.content.appointment_id) {
                      this.is_clone = true;
                      this.appointmentID = clone.content.appointment_id || null;
                      this.getDetailsBaseAppointment();
                    }
                  });
              }

              if (res.content.teacher_id) {
                this.teacher = {
                  value: res.content.teacher_id,
                  label: res.content.teacher_name,
                  active: res.content.teacher_active
                };
              }

              if (res.content.appointment_id) {
                this.appointmentID = res.content.appointment_id || null;
                this.getDetailsBaseAppointment();
              }
            }
          })
          .catch((error) => {
            this.$utils.toastError("Notificação", error);
            setTimeout(() => {
              this.$router.push({
                path: "/audio-visual/upload",
              });
            }, 3000);
          });
      }
    },
    createRecording(_formOccurrence) {
      if (!this.recordingID) {
        this.isLoading = true;
        let payload = {
          data: {
            teacher_id: this.teacherID,
            occurrence: {
              ..._formOccurrence,
              teacher_id: this.teacherID,
            },
          },
        };        

        _recordingService
          .store(payload)
          .then((res) => {
            if (res.content) {
              this.recordingID = res.content.id;
              this.teacherID = null;
              this.$router.push({
                path: `/audiovisual/recording/edit/${res.content.id}`,
              });
              this.getRecord();
              this.getRecordDetails();
              this.isOpenModal = false;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isLoading = false));
      }
    },
    getDetailsBaseAppointment() {
      if (this.appointmentID && this.appointmentID > 0) {
        this.isloading = true;
        _appointmentService
          .find_short(this.appointmentID)
          .then((res) => {
            if (this.is_clone) {
              this.appointment = {
                participants: res.content.participants,
                appointmentId: this.appointmentID
              };
            } else {
              this.appointment = {
                ...res.content,
                appointmentId: this.appointmentID
              };
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    goBack() {
      this.$router.push({
        path: "/audio-visual/upload",
      });
    },
  },
};
</script>