import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  store: (payload) => {
    return _http.post(`/api/audio-visual/minutagem`, payload);
  }, 
  update:  (payload) => {
    return _http.put(`/api/audio-visual/minutagem`, payload);
  }, 
  find: (id) => {
    return _http.get(`/api/audio-visual/minutagem/${id}`);
  },  
  delete: (id) => {
    return _http.delete(`/api/audio-visual/minutagem/${id}`);
  },  
  showByMediaSubject: (id) => {
    return _http.get(`/api/audio-visual/minutagem/show-by-media-subject/${id}`)
  }
}