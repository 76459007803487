var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"enctype":"multipart/form-data"}},[_c('div',{staticClass:"dropbox",class:[ _vm.lightMode ? 'skin-light' : 'skin-dark' ],style:([
      _vm.width
        ? { width: _vm.width + 'px', height: _vm.height + 20 + 'px' }
        : { minHeight: '245px' },
      _vm.listMedias.length > 0
        ? ''
        : { justifyContent: 'center', display: 'flex' }
    ])},[_c('input',{staticClass:"input-file",style:(_vm.width
          ? { width: _vm.width + 'px', height: _vm.height + 20 + 'px' }
          : { width: '99%', minHeight: '95%' }),attrs:{"type":"file","accept":_vm.fileType,"multiple":_vm.multiple},on:{"change":function($event){return _vm.filesChange($event.target.files)}}}),(!_vm.urlImage && _vm.listMedias.length == 0)?_c('p',[_c('feather-icon',{staticStyle:{"margin-bottom":"5px"},attrs:{"icon":_vm.icon,"size":"25"}}),_c('br'),_vm._v(" Arraste e solte aqui"),_c('br'),_vm._v(" ou clique para buscar ")],1):_vm._e(),(_vm.urlImage)?_c('img',{staticClass:"imagem img-fluid cursor-pointer",style:(_vm.width
          ? { width: _vm.width + 'px', height: _vm.height + 'px' }
          : { height: '225px' }),attrs:{"src":_vm.urlImage}}):_vm._e(),(_vm.urlImage)?_c('div',{staticClass:"title"},[_vm._v("Preview 16:9")]):_vm._e(),(_vm.listMedias.length > 0)?_c('b-row',{staticClass:"m-0"},_vm._l((_vm.listMedias),function(item,index){return _c('div',{key:index,class:[item.excluded && _vm.viewExcluded ? 'd-none' : 'video-card', ("skin-video-" + _vm.skin)]},[_vm._v(" "+_vm._s(item.name)+" "),_c('feather-icon',{staticClass:"m-0",staticStyle:{"position":"relative","top":"-15px","right":"-10px"},attrs:{"icon":item.excluded ? 'PlusIcon' : 'XIcon'},on:{"click":function($event){return _vm.removeMediaItem(item)}}}),_c('b-overlay',{attrs:{"show":item.loading,"variant":_vm.skin,"rounded":"sm","spinner-small":""}},[_c('b-progress',{class:item.excluded ? 'progress-bar-warning' : 'progress-bar-success',staticStyle:{"margin-top":"3px"},attrs:{"max":"100","variant":item.erro ? 'danger' : item.excluded ? 'warning' : 'success',"animated":item.uploaded ? false : true}},[_c('b-progress-bar',{attrs:{"value":item.erro ? '100' : item.progress}},[(item.excluded)?_c('strong',{staticStyle:{"font-size":"7pt"}},[_vm._v(" Apagado ")]):(item.erro || item.progress == 100)?_c('strong',{staticStyle:{"font-size":"7pt"}},[_vm._v(" "+_vm._s(item.erro ? "Error!" : item.uploaded ? "Salvo" : "Pronto!")+" ")]):_vm._e()])],1)],1)],1)}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }