<template>
  <b-overlay
    :show="overlay"
    :variant="skin"
    opacity="1"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-modal
      v-model="show"
      ref="modal-obs-list"
      title="Minutagem"
      hide-footer
      scrollable
      v-if="items"
      @show="setDotOptions"
      @hidden="() => $emit('obsHidden')"
    >
      <b-row style="min-height: 200px; margin-top: 10px">
        <b-col cols="12" v-if="items.length != 0">
          <b-list-group v-for="(element, id) in items" :key="id">
            <b-list-group-item class="p-50 mb-1">
              <p class="ml-25">{{ element.obs }}</p>
              <hr class="mb-50" />
              <div class="d-flex justify-content-between align-items-end">
                <b-badge variant="info" style="min-width: 70px">
                  {{ element.obs_time + " - " + element.obs_end_time }}
                </b-badge>
                <div>
                  <feather-icon
                    :id="`modal-obs-${element.id}`"
                    icon="Edit3Icon"
                    class="mr-50"
                    @click="editMinutagem(element)"
                  />
                  <feather-icon
                    :id="`modal-obs-${element.id}`"
                    icon="Trash2Icon"
                    class="text-danger"
                    @click="deleteMinutagem(element)"
                  />
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col v-else class="mt-1" style="text-align: center">
          Nenhuma minutagem cadastrada
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 8px; margin-top: 20px">
        <b-col style="text-align: left">
          <b-button variant="info" @click="addMinutagem">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Adicionar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- New Minutagem -->
    <b-modal
      ref="modal-obs"
      centered
      hide-footer
      :title="minutagem.id == 0 ? 'Nova Minutagem' : 'Alterar Minutagem'"
      @shown="startObsOptions"
    >
      <b-row>
        <b-col class="d-flex justify-content-center" cols="12">
          <video
            :src="currentMediaElement.preview"
            :id="`video-${currentMediaId}-mini`"
            width="100%"
            @seeking="minutePlayerCurrentTimeChange(currentMediaId, true)"
            @timeupdate="minutePlayerCurrentTimeChange(currentMediaId, true)"
          />
        </b-col>
        <b-col cols="12" class="mt-0">
          <vue-slider
            v-model="dotValues"
            :dot-options="dotOptions"
            :min="0"
            :max="currentMediaElement.pip.duration"
            direction="ltr"
            :interval="1"
            :duration="0"
            :tooltip-formatter="(v) => formatterDurarion(v)"
            @change="minutePlayerCurrentTimeChange(currentMediaId, false)"
            class="vue-slider-secondary"
          />
        </b-col>
        <b-col cols="12">
          <b-row align-v="center" align-h="between">
            <b-col cols="1">
              <b-button
                variant="flat-secondary"
                class="p-50 btn-icon rounded-circle"
                @click="playingChange(`${currentMediaId}-mini`)"
              >
                <feather-icon
                  :icon="playingIconChange(`${currentMediaId}-mini`)"
                  size="17"
                />
              </b-button>
            </b-col>
            <b-col cols="3">
              <div class="d-inline-flex">
                <b-button
                  variant="flat-secondary"
                  class="p-50 btn-icon rounded-circle"
                  @click="minuteVolChange(currentMediaId, true)"
                >
                  <feather-icon :icon="volDotIcon" size="17" />
                </b-button>
                <vue-slider
                  style="width: 60px; margin-top: 7px"
                  direction="ltr"
                  :interval="0.1"
                  :duration="0"
                  :min="0"
                  :max="1"
                  :tooltip="'none'"
                  v-model="volDot"
                  @change="minuteVolChange(currentMediaId)"
                  class="vue-slider-secondary"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr class="mt-50" />
      <b-row>
        <b-col>
          <b-form-group label="Início *">
            <b-input-group>
              <b-input-group-prepend>
                <b-button
                  variant="gradient-info"
                  v-if="editTime"
                  @click="
                    setMinuteTimeline(currentMediaId, true, validNoteTimeStart)
                  "
                >
                  <feather-icon
                    style="
                      float: right;
                      position: absolute;
                      right: 12px;
                      top: 10px;
                    "
                    icon="EyeIcon"
                    size="16"
                  />
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                :readonly="!editTime"
                style="max-width: 91%"
                v-model="minutagem.obs_time"
                placeholder="HH:mm:ss"
                v-mask="$utils.masked.time"
                :state="minutagem.obs_time.length == 8 && validNoteTimeStart"
              />
              <b-input-group-append>
                <b-button
                  variant="gradient-info"
                  v-if="editTime"
                  @click="setMinutesObs(currentMediaId, true)"
                >
                  <feather-icon
                    style="
                      float: left;
                      position: absolute;
                      left: 12px;
                      top: 10px;
                    "
                    icon="ScissorsIcon"
                    size="16"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Fim *">
            <b-input-group>
              <b-input-group-prepend>
                <b-button
                  variant="gradient-info"
                  v-if="editTime"
                  @click="
                    setMinuteTimeline(currentMediaId, false, validNoteTimeEnd)
                  "
                >
                  <feather-icon
                    style="
                      float: right;
                      position: absolute;
                      right: 12px;
                      top: 10px;
                    "
                    icon="EyeIcon"
                    size="16"
                  />
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                :readonly="!editTime"
                style="max-width: 91%"
                v-model="minutagem.obs_end_time"
                placeholder="HH:mm:ss"
                v-mask="$utils.masked.time"
                :state="minutagem.obs_end_time.length == 8 && validNoteTimeEnd"
              />
              <b-input-group-append>
                <b-button
                  variant="gradient-info"
                  v-if="editTime"
                  @click="setMinutesObs(currentMediaId)"
                >
                  <feather-icon
                    style="
                      float: left;
                      position: absolute;
                      left: 12px;
                      top: 10px;
                    "
                    icon="ScissorsIcon"
                    size="16"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-textarea
        :readonly="!editObs"
        rows="7"
        v-model="minutagem.obs"
        placeholder="Descrição..."
        :state="minutagem.obs.length > 0"
      />
      <hr />
      <b-button variant="info" @click="saveMinutagem">
        <span v-if="minutagem.id == 0">Adicionar</span>
        <span v-else>Alterar</span>
      </b-button>
    </b-modal>
    <!--End New Note -->
  </b-overlay>
</template>

<script>
import draggable from "vuedraggable";
import {
  BOverlay,
  BListGroup,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BRow,
  BCol,
  BIcon,
  BIconGripVertical,
  VBTooltip,
} from "bootstrap-vue";
import _disciplineService from "@/services/discipline-service";
import _companieService from "@/services/company-service";
import _recordingPendingService from "@/services/audiovisual/recording-pending";
import _productService from "@/services/audiovisual/products-service";
import _institutionsService from "@/services/audiovisual/institutions-issues";
import _projectService from "@/services/projects-service";
import _minutagemService from "@/services/audiovisual/minutagem-service";
import useAppConfig from "@core/app-config/useAppConfig";
import VueSlider from "vue-slider-component";

import { mapState } from "vuex";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    draggable,
    VueSlider,
    BOverlay,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BRow,
    BCol,
    BIcon,
    BIconGripVertical,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapState({
      currentMediaId: (state) => state.toolbar.videoPlayer.videoId,
      currentMediaElement: (state) => state.toolbar.videoPlayer.videoElement,
    }),
    dotValues: {
      get() {
        return [this.minDot, this.mainDot, this.maxDot];
      },
      set([minDot, mainDot, maxDot]) {
        this.minDot = minDot;
        this.mainDot = mainDot;
        this.maxDot = maxDot;
      },
    },
    validNoteTimeStart() {
      // calcula a diferença de milesegundos usando o mesmo dia
      let obstime = new Date("December 25, 1995 " + this.minutagem.obs_time);
      let start = new Date("December 25, 1995 " + this.subjectActive.start);
      let end = new Date("December 25, 1995 " + this.subjectActive.end);
      if (obstime >= start && obstime < end) return true;
      return false;
    },
    validNoteTimeEnd() {
      let obsendtime = new Date(
        "December 25, 1995 " + this.minutagem.obs_end_time
      );
      let start = new Date("December 25, 1995 " + this.subjectActive.start);
      let end = new Date("December 25, 1995 " + this.subjectActive.end);
      if (obsendtime <= end && obsendtime > start) return true;
      return false;
    },
  },
  data() {
    return {
      editTime: this.isNewRecording
        ? true
        : this.$utils.isPermited("permission.audiovisual.recorgind.edit.time"),
      editName: this.isNewRecording
        ? true
        : this.$utils.isPermited("permission.audiovisual.recorgind.edit.name"),
      editObs: this.isNewRecording
        ? true
        : this.$utils.isPermited("permission.audiovisual.recorgind.edit.obs"),
      minutagem: {
        id: 0,
        media_subject_id: 0,
        obs: "",
        obs_time: "",
        obs_end_time: "",
        created_at: "",
        updated_at: "",
      },
      dotOptions: [
        {
          style: { display: "none" },
          min: 0,
          max: 0,
          disabled: true,
        },
        {
          min: 0,
          max: 0,
          disabled: false,
        },
        {
          style: { display: "none", "z-index": 4 },
          min: 0,
          max: 0,
          disabled: true,
        },
      ],
      minDot: 0,
      mainDot: 0,
      maxDot: 0,
      volDot: 0.5,
      volDotIcon: "Volume1Icon",
      loading: false,
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    subjectActive: {
      required: true,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    //INICIO MINUTAGEM
    formatterDurarion(_duration) {
      let hours = Math.floor(_duration / 3600);
      let minutes = Math.floor((_duration - hours * 3600) / 60);
      let seconds = Math.floor(_duration % 60);

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      return hours + ":" + minutes + ":" + seconds;
    },
    setMinuteTimeline(_id, _isStart, _validate) {
      if (!_validate) return;

      let video = document.getElementById(`video-${_id}-mini`);
      let splitedArray = [];

      if (_isStart) {
        if (!this.minutagem.obs_time || this.minutagem.obs_time.length < 8)
          return;
        splitedArray = this.minutagem.obs_time.split(":");
      } else {
        if (
          !this.minutagem.obs_end_time ||
          this.minutagem.obs_end_time.length < 8
        )
          return;
        splitedArray = this.minutagem.obs_end_time.split(":");
      }

      let hours = parseInt(splitedArray[0] * 3600);
      let minutes = parseInt(splitedArray[1] * 60);
      let seconds = parseInt(splitedArray[2]);

      video.currentTime = hours + minutes + seconds;

      this.mainDot =
        video.currentTime > Math.floor(video.duration)
          ? Math.floor(video.currentTime)
          : video.currentTime;
    },
    startObsOptions() {
      let meuVideo = document.getElementById(
        `video-${this.currentMediaId}-mini`
      );
      meuVideo.currentTime = this.minDot;
    },
    setDotOptions() {
      let meuVideo = document.getElementById(`video-${this.currentMediaId}`);
      if (meuVideo) meuVideo.pause();
      if (this.subjectActive.start && this.subjectActive.start.length == 8) {
        let splitedArray = this.subjectActive.start.split(":");
        let hours = parseInt(splitedArray[0] * 3600);
        let minutes = parseInt(splitedArray[1] * 60);
        let seconds = parseInt(splitedArray[2]);
        this.dotOptions[0].min = hours + minutes + seconds;
        this.dotOptions[0].max = hours + minutes + seconds;
        this.dotOptions[1].min = hours + minutes + seconds;
        this.minDot = hours + minutes + seconds;
        this.mainDot = hours + minutes + seconds;
      }
      if (this.subjectActive.end && this.subjectActive.end.length == 8) {
        let splitedArray = this.subjectActive.end.split(":");
        let hours = parseInt(splitedArray[0] * 3600);
        let minutes = parseInt(splitedArray[1] * 60);
        let seconds = parseInt(splitedArray[2]);
        this.dotOptions[2].min = hours + minutes + seconds;
        this.dotOptions[2].max = hours + minutes + seconds;
        this.dotOptions[1].max = hours + minutes + seconds;
        this.maxDot = hours + minutes + seconds;
      }
      this.volDot = 0.5;
      this.volDotIcon = "Volume1Icon";
    },

    setMinutesObs(_i, _isStart = false) {
      var video = document.getElementById(`video-${_i}-mini`);
      let hours = Math.floor(video.currentTime / 3600);
      let minutes = Math.floor((video.currentTime - hours * 3600) / 60);
      let seconds = Math.floor(video.currentTime % 60);
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;
      if (_isStart) {
        this.minutagem.obs_time = hours + ":" + minutes + ":" + seconds;
      } else {
        this.minutagem.obs_end_time = hours + ":" + minutes + ":" + seconds;
      }
    },
    minutePlayerCurrentTimeChange(_id, _origin) {
      var meuVideo = document.getElementById(`video-${_id}-mini`);
      if (meuVideo) {
        if (_origin) {
          if (meuVideo.currentTime > this.maxDot) {
            meuVideo.currentTime = this.minDot;
            meuVideo.pause();
            return;
          }
          if (meuVideo.currentTime < this.minDot) {
            meuVideo.currentTime = this.mainDot;
          } else {
            this.mainDot =
              meuVideo.currentTime > Math.floor(meuVideo.duration)
                ? Math.floor(meuVideo.currentTime)
                : meuVideo.currentTime;
          }
        } else {
          meuVideo.currentTime = this.mainDot;
        }
      }
    },
    minuteVolChange(_id, isMute = false) {
      var meuVideo = document.getElementById(`video-${_id}-mini`);
      if (meuVideo) {
        if (isMute) {
          if (meuVideo.volume == 0) {
            if (this.volDot > 0) {
              meuVideo.volume = this.volDot;
            } else {
              meuVideo.volume = 0.5;
              this.volDot = 0.5;
            }
          } else {
            meuVideo.volume = 0;
          }
        } else {
          meuVideo.volume = this.volDot;
        }
        //Atualiza o icone de volume
        if (meuVideo.volume == 1) {
          this.volDotIcon = "Volume2Icon";
        } else if (meuVideo.volume == 0) {
          this.volDotIcon = "VolumeXIcon";
        } else if (meuVideo.volume <= 0.4) {
          this.volDotIcon = "VolumeIcon";
        } else if (meuVideo.volume >= 0.5) {
          this.volDotIcon = "Volume1Icon";
        }
      }
    },
    volChange(_item, _id, isMute = false) {
      var meuVideo = document.getElementById(`video-${_id}`);
      if (meuVideo) {
        if (isMute) {
          if (meuVideo.volume == 0) {
            if (_item.pip.currentVol > 0) {
              meuVideo.volume = _item.pip.currentVol;
            } else {
              meuVideo.volume = 0.5;
              _item.pip.currentVol = 0.5;
            }
          } else {
            meuVideo.volume = 0;
          }
        } else {
          meuVideo.volume = _item.pip.currentVol;
        }
        //Atualiza o icone de volume
        if (meuVideo.volume == 1) {
          _item.pip.currentVolIcon = "Volume2Icon";
        } else if (meuVideo.volume == 0) {
          _item.pip.currentVolIcon = "VolumeXIcon";
        } else if (meuVideo.volume <= 0.4) {
          _item.pip.currentVolIcon = "VolumeIcon";
        } else if (meuVideo.volume >= 0.5) {
          _item.pip.currentVolIcon = "Volume1Icon";
        }
      }
    },
    playingChange(_id) {
      var meuVideo = document.getElementById(`video-${_id}`);
      if (meuVideo) {
        if (meuVideo.paused) {
          meuVideo.play();
        } else {
          meuVideo.pause();
        }
      }
    },
    playingIconChange(_id) {
      var meuVideo = document.getElementById(`video-${_id}`);
      if (meuVideo) {
        if (meuVideo.paused) {
          return "PlayIcon";
        } else {
          return "PauseIcon";
        }
      }
      return "PlayIcon";
    },
    addMinutagem() {
      this.initMinutagem();
      this.minutagem.media_subject_id = this.subjectActive.id;
      this.$refs["modal-obs"].show();
    },
    closeModalMinutagem() {
      this.$refs["modal-obs-list"].hide();
    },
    saveMinutagem() {
      this.loading = true;
      let payload = {
        data: this.minutagem,
      };

      if (this.minutagem.id === 0) {
        _minutagemService
          .store(payload)
          .then((res) => {
            if (res.content) {
              this.items.push(res.content);
              this.initMinutagem();
              this.$refs["modal-obs"].hide();
            }
            this.loading = false;
          })
          .catch((error) => (this.loading = false));
      } else {
        _minutagemService
          .update(payload)
          .then((res) => {
            if (res.content) {
              this.$emit("getMinutagem", this.subjectActive);
              this.initMinutagem();
              this.$refs["modal-obs"].hide();
            }
            this.loading = false;
          })
          .catch((error) => (this.loading = false));
      }
    },
    editMinutagem(item) {
      this.minutagem = {
        id: item.id,
        obs: item.obs,
        obs_time: item.obs_time,
        obs_end_time: item.obs_end_time,
        media_subject_id: item.media_subject_id,
        created_at: item.created_at,
        updated_at: item.updated_at,
      };

      this.$refs["modal-obs"].show();
    },
    async deleteMinutagem(item) {
      this.loading = true;

      await _minutagemService
        .find(item.id)
        .then(async (res) => {
          if (res.content) {
            await _minutagemService
              .delete(item.id)
              .then((res) => {
                if (res.content) {
                  this.$emit("getMinutagem", this.subjectActive);
                }
                this.loading = false;
              })
              .catch((error) => (this.loading = false));
          }
          }).catch((error) => (this.loading = false));
    },
    initMinutagem() {
      this.minutagem = {
        id: 0,
        obs: "",
        obs_time: "",
        obs_end_time: "",
        media_subject_id: "",
        created_at: "",
        updated_at: "",
      };
    },
    //FIM MINUTAGEM
  },
};
</script>

<style scoped>
.sandbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.label {
  display: block;
  position: absolute;
  top: -18px;
  left: 25px;
  background: white;
  padding: 0px 7px 0px 7px;
}
.draggable-handle {
  display: block;
  background: #5a6172;
  color: white;
  border-radius: 5px 0px 0px 5px;
  min-width: 30px;
  cursor: move;
  z-index: 100;
}
.draggable-body {
  padding: 0.75rem 1.25rem;
  cursor: auto;
}
.draggable-item {
  border-width: 1px;
  border-radius: 5px;
}
.list-group-item:last-child {
  border-bottom-width: 1px;
}
.draggable-handle .index {
  position: absolute;
  top: 10px;
}
</style>
