<template>
  <b-overlay :variant="skin" :show="loading" rounded="sm">
    <!-- LISTA DE MATERIAIS -->
    <draggable
      v-model="items"
      role="tablist"
      handle=".draggable-handle"
      @change="updateOrder"
    >
      <b-card
        v-for="(item, i) in items"
        :key="i"
        no-body
        :class="['accordion-card', `accordion-card-bg-${skin}`]"
      >
        <div
          header-tag="header"
          class="accordion-header justify-content-between align-items-center"
          role="tab"
          v-b-toggle="`accordion-${i}`"
        >
          <div class="d-flex">
            <div
              class="draggable-handle d-flex justify-content-center align-items-center"
              :class="[{ 'show-handle': currentAccordion != null }]"
            >
              <b-icon icon="grip-vertical" font-scale="1.6"></b-icon>
            </div>
            <div class="d-flex">
              <span class="accordion-header-title">
                {{ `Material ${i + 1}` }} <br />
                {{ `Arquivo: ${item.name}` }}
                <span style="color: #9aa1b0">
                  {{ `(${item.original_filename})` }}
                </span>
              </span>
            </div>
          </div>
          <div>
            <b-badge
              :variant="item.type_media == 1 ? 'success' : 'info'"
              class="mr-2"
            >
              {{ descType(item.type_media) }}
            </b-badge>
            <feather-icon
              class="mr-1"
              :class="{
                active: currentAccordion == i,
                inactive: currentAccordion != i,
              }"
              icon="ChevronUpIcon"
              size="18"
            />
          </div>
        </div>
        <b-collapse
          :id="`accordion-${i}`"
          accordion="my-accordion"
          role="tabpanel"
          @show="activeAccordion(i, item)"
          @hide="inactiveAccordion(i, item)"
        >
          <b-card-body v-if="item.pip" class="accordion-body">
            <b-overlay
              :variant="skin"
              :show="!item.pip.duration || !mediaLoaded"
              rounded="sm"
            >
              <b-row
                class="pt-1 pb-50"
                :style="{ backgroundColor: lightMode ? '#f7f7f7' : '#2E353B' }"
              >
                <!-- ajuda robson imagem aqui -->
                <b-col cols="auto">
                  <video
                    :src="item.preview"
                    :id="`media-${i}`"
                    :poster="
                      item.type_media == 1
                        ? require('@/assets/images/svg/audio_play.svg')
                        : null
                    "
                    height="440"
                    style="background: #c4c4c4"
                    @seeking="currentTimeChange(item, i, true)"
                    @timeupdate="currentTimeChange(item, i, true)"
                    @loadedmetadata="mediaLoaded = true"
                  />
                  <div>
                    <b v-if="item.pip.duration">
                      {{ formatterDurarion(item) }}
                    </b>
                  </div>
                </b-col>

                <b-col>
                  <div
                    :class="[
                      'mb-1',
                      'appointment-card',
                      `appointment-card-bg-${skin}`,
                    ]"
                    v-if="
                      appointment &&
                      ((appointment.products &&
                        appointment.products.length > 0) ||
                        appointment.discipline_name)
                    "
                  >
                    <div
                      v-if="
                        appointment.products && appointment.products.length > 0
                      "
                    >
                      <b>Produto(s) sinalizado(s):</b>
                      <b-badge
                        v-for="(product, index) in appointment.products"
                        :key="index"
                        variant="light-info"
                        class="m-25"
                      >
                        {{ product }}
                      </b-badge>
                    </div>
                    <div v-if="appointment && appointment.discipline_name">
                      <b>Disciplina sinalizada:</b>
                      <b-badge variant="light-warning" class="m-25">
                        {{ appointment.discipline_name }}
                      </b-badge>
                    </div>
                    <div v-if="appointment && appointment.classification_name">
                      <b>Classificação sinalizada:</b>
                      <b-badge variant="light-warning" class="m-25">
                        {{ appointment.classification_name }}
                      </b-badge>
                    </div>
                  </div>

                  <UploadPDF
                    :file="item.pdf"
                    :patchSave="true"
                    :dirS3="DIR_FILE_UPLOAD"
                    :fileType="fileTypeUpload"
                    :mediaFileID="item.media_file_id"
                    :limitSizeFile="LIMIT_SIZE_FILE_UPLOAD"
                    @initUpload="inUploadPDF = true"
                    @uploadFail="inUploadPDF = false"
                    @newFile="
                      (file) => {
                        item.pdf = file;
                        inUploadPDF = false;
                      }
                    "
                    @remove="clearPdfPages(item.timeline.subjects)"
                  />

                  <b-button
                    variant="info"
                    @click="newSubject(item)"
                    style="
                      position: absolute;
                      bottom: 25px;
                      right: 0px;
                      min-width: 120px;
                    "
                    class="mr-1"
                    :disabled="inUploadPDF"
                  >
                    <div v-if="!inUploadPDF">
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>Assunto</span>
                    </div>
                    <b-spinner v-else small />
                  </b-button>
                </b-col>
              </b-row>
            </b-overlay>
            <section
              v-if="
                item.pip.duration &&
                mediaLoaded &&
                item.timeline &&
                item.timeline.subjects &&
                item.timeline.subjects.length > 0
              "
            >
              <b-row>
                <b-col md="8">
                  <Subjects
                    group="subjects"
                    :mediaDuration="item.pip.duration"
                    :items="item.timeline.subjects"
                    :pdf="item.pdf"
                    :mediaFilesID="item.recordingMediaFilesID"
                    :activeAutoSave="activeAutoSave"
                    :appointmentId="appointmentId"
                  />
                </b-col>
                <!-- <b-col>
                  <Subjects group="subjects" :items="fakeSandbox" sandbox />
                </b-col> -->
              </b-row>
            </section>
            <p class="text-center mt-2" v-else>
              Nenhum assunto vinculado ao material
            </p>
          </b-card-body>
        </b-collapse>
      </b-card>
    </draggable>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  VBPopover,
  BPopover,
  BFormFile,
  BCollapse,
  VBToggle,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BIcon,
  BIconGripVertical,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import VueSlider from "vue-slider-component";
import UploadPDF from "./UploadPDF.vue";
import Subjects from "./Subjects.vue";
import { mapMutations } from "vuex";
import useAppConfig from "@core/app-config/useAppConfig";
import draggable from "vuedraggable";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import _mediaSubjectVideoService from "@/services/audiovisual/media-subject-video-service";
import _mediaSubjectAudioService from "@/services/audiovisual/media-subject-audio-service";
import _mediaFilesService from "@/services/audiovisual/media-files-service";
import _mediaSubjecDraftsService from "@/services/audiovisual/media-subject-drafts-service";
import _recordingMediaFilesService from "@/services/audiovisual/recording-media-files";
import { _classificationService } from '@/services/classification-service';

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    AppCollapse,
    AppCollapseItem,
    VBPopover,
    BPopover,
    AppTimeline,
    AppTimelineItem,
    BFormFile,
    VueSlider,
    UploadPDF,
    Subjects,
    draggable,
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BIcon,
    BIconGripVertical,
    FeatherIcon,
  },
  directives: {
    "b-popover": VBPopover,
    "b-toggle": VBToggle,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    recording_media_id: {
      type: Number,
      default: 0,
    },
    appointment: {
      type: Object,
      required: false,
    },
    teacher: {
      type: Object,
    },
  },
  watch: {
    "popoverPlayerShow.status": {
      handler(_newValue, _oldValue) {
        this.popoverPlayerShow.old = _oldValue;
      },
    },
  },
  computed: {
    lightMode() {
      return this.skin == "light" ? true : false;
    },
    appointmentId() {
      return this.appointment ? this.appointment.appointmentId : null;
    },
  },
  data() {
    return {
      loading: false,
      activeAutoSave: true,
      inUploadPDF: false,
      mediaLoaded: false,
      TYPE_RECORDING: 0,
      TYPE_MEDIA_FILE: 1,
      observer: null,
      inPiP: false,
      popoverPlayerShow: {
        status: false,
        old: false,
      },
      currentAccordion: null,
      fakeSandbox: null,
      fileTypeUpload: [".pdf", ".pptx"],
      UPLOAD_FILE: process.env.VUE_APP_UPLOAD_FILE_SUBJECT,
      DIR_FILE_UPLOAD: "",
      LIMIT_SIZE_FILE_UPLOAD: 0,
    };
  },
  async destroyed() {
    this.observer.disconnect();
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  },
  created() {
    this.handleUpload();
    // INSTANCIA UM OBSERVADOR
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            !entry.isIntersecting &&
            document.pictureInPictureEnabled &&
            !this.isAudioType(entry.target.currentSrc)
          ) {
            entry.target.requestPictureInPicture().then(() => {
              this.inPiP = false;
            });
          } else if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
            this.inPiP = false;
          }
        });
      },
      { threshold: 0.5 }
    );
  },
  methods: {
    ...mapMutations({
      UPDATE_CURRENT_TOOLBAR: "toolbar/UPDATE_CURRENT_TOOLBAR",
      UPDATE_CURRENT_VIDEO: "toolbar/videoPlayer/UPDATE_CURRENT_VIDEO",
    }),
    clearPdfPages(subjects) {
      if (subjects && Array.isArray(subjects)) {
        this.activeAutoSave = false;
        for (const sub of subjects) {
          sub.page_start = null;
          sub.page_end = null;
        }
        setTimeout(() => {
          this.activeAutoSave = true;
        }, 100);
      }
    },
    formatterDurarion(item) {
      let hours = Math.floor(item.pip.duration / 3600);
      let minutes = Math.floor((item.pip.duration - hours * 3600) / 60);
      let seconds = Math.floor(item.pip.duration % 60);

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      let result = hours + ":" + minutes + ":" + seconds;
      if (!item.duration && !item.saveDuration) {
        this.saveDuration(item, result);
      }

      return result;
    },
    saveDuration(item, duration) {
      const payload = [
        {
          path: "/media_time",
          op: "replace",
          value: duration,
        },
      ];
      _recordingMediaFilesService
        .saveDuration(item.media_file_id, payload)
        .then((_res) => (item.saveDuration = true))
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    changeCurrentVideo(mode, item, id) {
      var media = document.getElementById(`media-${id}`);
      this.observer.disconnect();
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
      if (media && media.duration) {
        let hours = Math.floor(media.duration / 3600);
        let minutes = Math.floor((media.duration - hours * 3600) / 60);
        let seconds = Math.floor(media.duration % 60);

        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;
        if (seconds < 10) seconds = "0" + seconds;

        item.pip.duration = Math.floor(media.duration);
        item.timeline.duration = { hours, minutes, seconds };

        // DEFINE O NOVO ALVO PRO OBSERVADOR
        if (mode) {
          setTimeout(() => {
            this.observer.observe(media);
            this.popoverPlayerShow.status = true;
          }, 500);
        }

        this.UPDATE_CURRENT_TOOLBAR(mode ? "video-player" : "none");
        this.UPDATE_CURRENT_VIDEO({ videoElement: item, videoId: id });
      } else {
        // SE O VIDEO AINDA NÃO FOI CARREGADO, CHAMA O METHODO NOVAMENTE
        setTimeout(() => {
          this.changeCurrentVideo(mode, item, id);
        }, 500);
      }
    },
    currentTimeChange(item, id) {
      var media = document.getElementById(`media-${id}`);
      if (media) {
        item.pip.currentTime =
          media.currentTime > Math.floor(media.duration)
            ? Math.floor(media.currentTime)
            : media.currentTime;
      }
    },
    newSubject(item) {
      let order = item.timeline.subjects ? item.timeline.subjects.length : 1;
      item.timeline.subjects.push({
        //atributos comuns em audio e vídeo
        searchInstitute: {
          institutions: [],
          loading: false,
        },
        searchDiscipline: {
          disciplines: [],
          loading: false,
          timer: null,
        },
        searchClassification: {
          classifications: this.appointment && this.appointment.classification_name ? [{
            value: this.appointment.classification_id,
            label: this.appointment.classification_name,
          }] : [],
          loading: false,
          timer: null,
        },
        searchProject: {
          projects: [],
          loading: false,
          timer: null,
        },
        searchTeacher: {
          teachers:
            this.appointment &&
            this.appointment.participants.filter((f) => f.type !== "guest")
              ? this.appointment.participants
                  .filter((f) => f.type !== "guest")
                  .map((m) => {
                    return { value: m.id, label: m.name, active: m.active };
                  })
              : [this.teacher],
          others: [],
          loading: false,
          timer: null,
        },
        pendencies: {
          loading: false,
          recordingMediaSubjectPendings: [],
        },
        pendencyExistent: null,
        institutionSelected: null,
        disciplineSelected:
          this.appointment && this.appointment.discipline_name
            ? {
                value: this.appointment.discipline_id,
                label: this.appointment.discipline_name,
              }
            : null,
        classificationSelected:
          this.appointment && this.appointment?.classification_name
            ? {
                value: this.appointment.classification_id,
                label: this.appointment.classification_name,
              }
            : null,
        projectSelected:
          this.appointment &&
          this.appointment.project_name &&
          !this.appointment.project_system_default
            ? {
                value: this.appointment.project_id,
                label: this.appointment.project_name,
              }
            : null,
        companiesSelected:
          this.appointment && this.appointment.company_name
            ? {
                value: this.appointment.company_id,
                label: this.appointment.company_name,
              }
            : null,
        productSelected: null,
        teacherSelected:
          this.appointment &&
          this.appointment.participants.filter((f) => f.type !== "guest")
            ? {
                value: this.appointment.participants[0].id,
                label: this.appointment.participants[0].name,
                active: this.appointment.participants[0].active,
              }
            : this.teacher,
        id: this.getKey(),
        name: "",
        start: "",
        end: "",
        obs: [],
        order: order++,
        recording_media_file_id: item.recordingMediaFilesID,
        type_media: item.type_media,

        // atributos específicos de vídeo
        question_year: null,
        question_identified: false,
        page_start: null,
        page_end: null,
        presentation_file_s3_key: "",
        media_file_name_file: item.presentation_file_s3_key
          ? item.presentation_file_s3_key
          : item.pdf.S3_key,
        loading: false,
      });
    },
    getKey(_name) {
      let result = `${Math.random().toString(16).substr(2)}${Math.random()
        .toString(16)
        .substr(2)}`;
      if (_name) {
        result += `.${_name.split(".")[_name.split(".").length - 1]}`;
      }
      return result;
    },
    overFlow(files) {
      if (files[0].name) {
        if (files[0].name.length > 35) {
          return files[0].name.substr(0, 35) + "...";
        } else {
          return files[0].name;
        }
      } else {
        return "--";
      }
    },
    async activeAccordion(index, item) {
      this.currentAccordion = index;
      if (!item.preview) {
        this.mediaLoaded = false;
        await this.getUrlMedia(item);
        await this.getSubjectsByRecording(item);
        await this.getUrlPDFPresentation(item);
        // await this.getDrafts();
      }
      this.changeCurrentVideo(true, item, index);
    },
    inactiveAccordion(index, item) {
      if (this.currentAccordion == index) {
        this.currentAccordion = null;
        this.changeCurrentVideo(false, item, index);
      }
    },
    async getSubjectsByRecording(item) {
      item.timeline.subjects = [];
      if (item.type_media == 0) {
        //video
        _mediaSubjectVideoService
          .showByRecordingMediaFile(item.recordingMediaFilesID)
          .then((res) => {
            if (res.content) {
              res.content.forEach((sub) => {
                item.timeline.subjects.push({
                  //atributos comuns em audio e vídeo
                  searchInstitute: {
                    institutions: [],
                    loading: false,
                  },
                  searchDiscipline: {
                    disciplines: [],
                    loading: false,
                    timer: null,
                  },
                  searchClassification: {
                    classifications: sub.classification_platform_id ? [{
                      value: sub.classification_platform_id,
                      label: sub.classification_platform_name
                    }] : [],
                    loading: false,
                    timer: null,
                  },
                  searchProject: {
                    projects: [],
                    loading: false,
                    timer: null,
                  },
                  searchTeacher: {
                    teachers:
                      this.appointment && this.appointment.participants
                        ? this.appointment.participants.map((m) => {
                            return { value: m.id, label: m.name, active: m.active };
                          })
                        : [this.teacher],
                    others: [],
                    loading: false,
                    timer: null,
                  },
                  pendencies: {
                    loading: false,
                    recordingMediaSubjectPendings: [],
                  },
                  pendencyExistent: null,
                  disciplineSelected: sub.discipline
                    ? {
                        value: sub.discipline.id,
                        label: sub.discipline.name,
                      }
                    : this.appointment && this.appointment.discipline_name
                    ? {
                        value: this.appointment.discipline_id,
                        label: this.appointment.discipline_name,
                      }
                    : null,
                  projectSelected: sub.project
                    ? {
                        value: sub.project.id,
                        label: sub.project.name,
                      }
                    : this.appointment &&
                      this.appointment.project_name &&
                      !this.appointment.project_system_default
                    ? {
                        value: this.appointment.project_id,
                        label: this.appointment.project_name,
                      }
                    : null,
                  companiesSelected:
                    sub.project && sub.project.company
                      ? {
                          value: sub.project.company.id,
                          label: sub.project.company.name,
                        }
                      : this.appointment && this.appointment.company_name
                      ? {
                          value: this.appointment.company_id,
                          label: this.appointment.company_name,
                        }
                      : null,
                  productSelected: sub.product
                    ? {
                        value: sub.product.id,
                        label: sub.product.name,
                      }
                    : null,
                  classificationSelected: sub.classification_platform_id ? {
                    value: sub.classification_platform_id,
                    label: sub.classification_platform_name
                  } : null,
                  teacherSelected: sub.teacher
                    ? {
                        value: sub.teacher.id,
                        label: sub.teacher.name,
                        active: sub.teacher.active
                      }
                    : this.appointment && this.appointment.participants
                    ? {
                        value: this.appointment.participants[0].id,
                        label: this.appointment.participants[0].name,
                        active: this.appointment.participants[0].active,
                      }
                    : this.teacher,
                  product_id: sub.product_id,
                  id: sub.id,
                  name: sub.subject,
                  start: sub.start_time,
                  end: sub.end_time,
                  obs: [],
                  order: sub.order,
                  type_media: sub.type_media,
                  recording_media_file_id: sub.recording_media_file_id,
                  loading: false,

                  // atributos específicos de vídeo
                  page_start: sub.page_start,
                  page_end: sub.page_end,
                  presentation_file_s3_key: sub.presentation_file_s3_key,
                  media_file_name_file: item.presentation_file_s3_key,
                  institutionSelected: sub.institutionsIssues
                    ? {
                        value: sub.institutionsIssues.id,
                        label: sub.institutionsIssues.name,
                      }
                    : null,
                  question_year: sub.question_year,
                  question_identified:
                    sub.subject === "questao_nao_identificada" ? true : false,
                });
              });
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
      if (item.type_media == 1) {
        //audio
        _mediaSubjectAudioService
          .showByRecordingMediaFile(item.recordingMediaFilesID)
          .then((res) => {
            if (res.content) {
              res.content.forEach((sub) => {
                item.timeline.subjects.push({
                  //atributos comuns em audio e vídeo
                  searchInstitute: {
                    institutions: [],
                    loading: false,
                  },
                  searchDiscipline: {
                    disciplines: [],
                    loading: false,
                    timer: null,
                  },
                  searchClassification: {
                    classifications: sub.classification_platform_id ? [{
                      value: sub.classification_platform_id,
                      label: sub.classification_platform_name
                    }] : [],
                    loading: false,
                    timer: null,
                  },
                  searchProject: {
                    projects: [],
                    loading: false,
                    timer: null,
                  },
                  searchTeacher: {
                    teachers:
                      this.appointment && this.appointment.participants
                        ? this.appointment.participants.map((m) => {
                            return { value: m.id, label: m.name, active: m.active };
                          })
                        : [this.teacher],
                    others: [],
                    loading: false,
                    timer: null,
                  },
                  pendencies: {
                    loading: false,
                    recordingMediaSubjectPendings: [],
                  },
                  pendencyExistent: null,
                  disciplineSelected: sub.discipline
                    ? {
                        value: sub.discipline.id,
                        label: sub.discipline.name,
                      }
                    : this.appointment && this.appointment.discipline_name
                    ? {
                        value: this.appointment.discipline_id,
                        label: this.appointment.discipline_name,
                      }
                    : null,
                  projectSelected: sub.project
                    ? {
                        value: sub.project.id,
                        label: sub.project.name,
                      }
                    : this.appointment &&
                      this.appointment.project_name &&
                      !this.appointment.project_system_default
                    ? {
                        value: this.appointment.project_id,
                        label: this.appointment.project_name,
                      }
                    : null,
                  companiesSelected:
                    sub.project && sub.project.company
                      ? {
                          value: sub.project.company.id,
                          label: sub.project.company.name,
                        }
                      : this.appointment && this.appointment.company_name
                      ? {
                          value: this.appointment.company_id,
                          label: this.appointment.company_name,
                        }
                      : null,
                  classificationSelected: sub.classification_platform_id ? {
                    value: sub.classification_platform_id,
                    label: sub.classification_platform_name
                  } : null,
                  productSelected: sub.product
                    ? {
                        value: sub.product.id,
                        label: sub.product.name,
                      }
                    : null,
                  teacherSelected: sub.teacher
                    ? {
                        value: sub.teacher.id,
                        label: sub.teacher.name,
                        active: sub.teacher.active
                      }
                    : this.appointment && this.appointment.participants
                    ? {
                        value: this.appointment.participants[0].id,
                        label: this.appointment.participants[0].name,
                        active: this.appointment.participants[0].active
                      }
                    : this.teacher,
                  product_id: sub.product_id,
                  id: sub.id,
                  name: sub.subject,
                  start: sub.start_time,
                  end: sub.end_time,
                  obs: [],
                  order: sub.order,
                  type_media: sub.type_media,
                  recording_media_file_id: sub.recording_media_file_id,
                  loading: false,
                });
              });
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
    async getUrlMedia(item) {
      _mediaFilesService
        .getUrlFile(item.name, this.TYPE_RECORDING)
        .then((res) => {
          if (res.content) {
            item.preview = res.content;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },

    async getDrafts() {
      this.fakeSandbox = [];
      _mediaSubjecDraftsService
        .showByRecordingMedia(this.recording_media_id)
        .then((res) => {
          if (res.content) {
            res.content.forEach((draft) => {
              this.fakeSandbox.push({
                //atributos comuns em audio e vídeo
                id: draft.id,
                subject: draft.subject,
                product_id: {
                  value: draft.product_id,
                  label: draft.product_name,
                },
                discipline_id: {
                  value: draft.discipline_id,
                  label: draft.discipline_name,
                },
                project_id: {
                  value: draft.project_id,
                  label: draft.project_name,
                },
                assigned: draft.assigned,
                company_id: {
                  value: 0,
                  label: draft.company_name,
                  // atributos específicos de vídeo
                  institution_issues_id: {
                    value: draft.institution_issues_id,
                    label: draft.institution_issues_name,
                  },
                  question_year: draft.question_year,
                },
              });
            });
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    updateOrder() {
      let listOrder = [];
      this.items.forEach((element, i) => {
        listOrder.push({
          id: element.recordingMediaFilesID,
          recording_media_id: this.recording_media_id,
          media_file_id: element.media_file_id,
          media_file_order: i,
          excluded: false,
        });
      });
      _recordingMediaFilesService
        .reorderList(listOrder)
        .then((res) => {})
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    async getUrlPDFPresentation(item) {
      item.pdf = {};
      if (item.presentation_file_s3_key) {
        _mediaFilesService
          .getUrlFile(item.presentation_file_s3_key, this.TYPE_MEDIA_FILE)
          .then((res) => {
            if (res.content) {
              item.pdf = {
                S3_url: res.content,
                progress: 100,
                inCloud: true,
                S3_key: item.presentation_file_s3_key,
              };
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
    descType(media_type) {
      switch (media_type) {
        case 0:
          return "Vídeo";
        case 1:
          return "Audio";
      }
    },
    handleUpload() {
      if (this.UPLOAD_FILE) {
        let params_upload_file = this.UPLOAD_FILE.split(",");
        if (params_upload_file.length == 2) {
          this.DIR_FILE_UPLOAD = params_upload_file[0];
          this.LIMIT_SIZE_FILE_UPLOAD = parseInt(params_upload_file[1]);
          if (Number.isNaN(this.LIMIT_SIZE_FILE_UPLOAD))
            this.LIMIT_SIZE_FILE_UPLOAD = 0;
        }
      }
    },
    isAudioType(contentPath) {
      const audioTypes = [".mp3", ".flac", ".wav", ".aac", "mqa", ".m4a"];
      for (const type of audioTypes) {
        if (contentPath.toLowerCase().search(type) > -1) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-slider.scss";
#popover-player {
  position: fixed;
  top: 110px;
  right: 110px;
  z-index: 100;
}
.popover {
  max-width: 100% !important;
}
.appointment-card {
  border-radius: 5px;
  padding: 10px;
}
.draggable-handle {
  transition: width 0.3s;
  display: block;
  background: #5a6172;
  color: white;
  border-radius: 5px 0px 0px 5px;
  width: 30px;
  cursor: move;
  z-index: 100;
}
.draggable-handle .index {
  position: absolute;
  top: 10px;
}
.accordion-header {
  display: flex;
  padding: 0px;
  min-height: 50px;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
}
.accordion-header .active {
  animation: active 0.3s both;
}
.accordion-header .inactive {
  animation: inactive 0.3s both;
}
.accordion-header .show-handle {
  overflow: hidden;
  width: 0px !important;
}
.accordion-card {
  margin: 15px 0px !important;
}
.accordion-header-title {
  padding: 10px;
  margin-left: 15px;
}
.accordion-card-bg-light {
  border: 1px solid rgba(47, 34, 42, 0.125);
}
.accordion-card-bg-dark {
  border: 1px solid rgba(175, 175, 175, 0.125);
}
.appointment-card-bg-light {
  background: #ffffff;
}
.appointment-card-bg-dark {
  background: #333a3e;
}
.accordion-card {
  border-radius: 5px;
}
.card-body {
  padding: 1rem;
}

@keyframes active {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes inactive {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
