<template>
  <b-overlay
    :show="overlay"
    :variant="skin"
    opacity="1"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <strong v-if="sandbox" class="label">Rascunhos de Assuntos</strong>
    <draggable
      :list="items"
      tag="ul"
      :group="group"
      class="mt-2 list-group list-group-flush"
      :class="{ sandbox: sandbox }"
      handle=".draggable-handle"
      @change="updateOrder"
    >
      <b-list-group-item
        v-for="(item, index) in items"
        :key="item.id"
        tag="li"
        class="my-0 p-0 d-flex draggable-item"
        :class="{ 'mb-1': index != items.length - 1 }"
      >
        <div
          class="
            draggable-handle
            d-flex
            justify-content-center
            align-items-center
          "
          :class="[{ 'show-handle': subjectError }]"
        >
          <div v-if="!sandbox" class="index">{{ index + 1 }}</div>
          <b-icon icon="grip-vertical" font-scale="2"></b-icon>
        </div>

        <div v-if="sandbox" class="draggable-body">
          <p class="mb-0">
            <b>Empresa: </b> {{ item.company_id.label }} <br />
            <b>Projeto: </b> {{ item.project_id.label }} <br />
            <b>Disciplina: </b> {{ item.discipline_id.label }} <br />
            <b>Produto: </b> {{ item.product_id.label }}
            <feather-icon
              v-if="item.product_id.value == 2"
              v-b-tooltip.hover.top="
                `(${item.question_year}) ${item.institution_issues_id.label}`
              "
              icon="InfoIcon"
              size="16"
              class="mb-25 ml-25"
            />
            <br />
            <b>{{ item.product_id.value == 2 ? "Questão: " : "Assunto: " }} </b>
            {{ item.subject }}
          </p>
        </div>
        <SubjectForm
          v-else
          :item="item"
          :pdf="pdf"
          :index="`${mediaFilesID}-${index}`"
          :hasPDF="Object.keys(pdf).length > 0"
          :mediaDuration="mediaDuration"
          :activeAutoSave="activeAutoSave"
          :appointmentId="appointmentId"
          @cloneSubject="() => cloneSubject(item)"
          @deleteSubject="() => deleteSubject(item.id, index)"
        />
      </b-list-group-item>
    </draggable>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BListGroup,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BRow,
  BCol,
  BIcon,
  BIconGripVertical,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import _recordingPendingService from "@/services/audiovisual/recording-pending";
import _mediaSubjectService from "@/services/audiovisual/media-subject-service";
import _convertPDFService from "@/services/convert-pdf-service";
import SubjectForm from "./SubjectForm.vue";
import { isNumber } from "@vueuse/shared";
import { mapMutations, mapState } from "vuex";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    draggable,
    BOverlay,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BRow,
    BCol,
    BIcon,
    BIconGripVertical,
    BSpinner,
    SubjectForm,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      editCreate: this.isNewRecording
        ? true
        : this.$utils.isPermited(
            "permission.audiovisual.recorgind.edit.create"
          ),
      editDelete: this.isNewRecording
        ? true
        : this.$utils.isPermited(
            "permission.audiovisual.recorgind.edit.delete"
          ),
      editProducts: this.isNewRecording
        ? true
        : this.$utils.isPermited(
            "permission.audiovisual.recorgind.edit.products"
          ),
      editCompanies: this.isNewRecording
        ? true
        : this.$utils.isPermited(
            "permission.audiovisual.recorgind.edit.companies"
          ),
      editTime: this.isNewRecording
        ? true
        : this.$utils.isPermited("permission.audiovisual.recorgind.edit.time"),
      editName: this.isNewRecording
        ? true
        : this.$utils.isPermited("permission.audiovisual.recorgind.edit.name"),
      editObs: this.isNewRecording
        ? true
        : this.$utils.isPermited("permission.audiovisual.recorgind.edit.obs"),
      TYPE_MEDIA_FILE: 2,
      show: false,
    };
  },
  props: {
    group: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    sandbox: {
      type: Boolean,
      default: false,
    },
    pdf: {
      type: Object,
      required: false,
    },
    mediaFilesID: {
      type: Number,
      required: true,
    },
    mediaDuration: {
      type: Number
    },
    activeAutoSave: {
      type: Boolean,
      required: true
    },
    appointmentId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState({
      subjectError: (state) => state.toolbar.videoPlayer.subjectError,
    }),
  },
  destroyed() {
    this.SUBJECT_VALIDATE_RESET();
  },
  methods: {
    ...mapMutations({
      SUBJECT_VALIDATE_RESET: "toolbar/videoPlayer/SUBJECT_VALIDATE_RESET",
    }),
    cloneSubject(_sub) {
      this.items.push({
        companiesSelected: _sub.companiesSelected,
        discipline: _sub.discipline,
        disciplineSelected: _sub.disciplineSelected,
        classificationSelected: _sub.classificationSelected,
        project: _sub.project,
        projectSelected: _sub.projectSelected,
        end: _sub.end,
        start: _sub.start,
        id: this.getKey(),
        institutionSelected: _sub.institutionSelected,
        name: _sub.name,
        obs: [],
        searchInstitute: _sub.searchInstitute,
        searchDiscipline: _sub.searchDiscipline,
        searchClassification: _sub.searchClassification,
        searchProject: _sub.searchProject,
        searchTeacher: _sub.searchTeacher,
        teachers: _sub.teachers,
        teacherSelected: _sub.teacherSelected,
        pendencies: _sub.pendencies,
        pendencyExistent: [],
        institutionSelected: _sub.institutionSelected,
        question_year: _sub.question_year,
        productSelected: _sub.productSelected,
        page_end: _sub.page_end,
        page_start: _sub.page_start,
        question_identified: _sub.question_identified,
        recording_media_file_id: this.mediaFilesID,
        order: this.items.length,
        presentation_file_s3_key: "",
        media_file_name_file: _sub.media_file_name_file,
        type_media: _sub.type_media,
        loading: false,
      });
    },
    getKey(_name) {
      let result = `${Math.random().toString(16).substr(2)}${Math.random()
        .toString(16)
        .substr(2)}`;
      if (_name) {
        result += `.${_name.split(".")[_name.split(".").length - 1]}`;
      }
      return result;
    },
    deleteSubject(id, index) {
      this.$swal({
        title: "Deseja excluir o assunto?",
        text: "Ao executar a ação as minutagens e pendências também serão excluídas!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (!isNumber(id)) {
            this.items.splice(index, 1);
            return;
          }

          this.loading = true;
          _mediaSubjectService
            .delete(id)
            .then((res) => {
              this.items.splice(index, 1);
              this.updateOrder();
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.loading = false));
        }
      });
    },
    updateOrder() {
      let listOrder = [];
      this.items.forEach((sub, i) => {
        if (isNumber(sub.id)) {
          listOrder.push({
            id: sub.id,
            order: i,
          });
        }
      });
      let payload = { data: listOrder };
      this.loading = true;
      _mediaSubjectService
        .reorderList(payload)
        .then((res) => {})
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.sandbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.label {
  display: block;
  position: absolute;
  top: -18px;
  left: 25px;
  background: white;
  padding: 0px 7px 0px 7px;
}

.draggable-handle {
  transition: width 0.3s;
  display: block;
  background: #5a6172;
  color: white;
  border-radius: 5px 0px 0px 5px;
  width: 30px;
  cursor: move;
  z-index: 100;
}

.draggable-body {
  padding: 0.75rem 1.25rem;
  cursor: auto;
}

.draggable-item {
  border-width: 1px;
  border-radius: 5px;
}

.list-group-item:last-child {
  border-bottom-width: 1px;
}

.draggable-handle .index {
  position: absolute;
  top: 10px;
}

.show-handle {
  overflow: hidden;
  width: 0px !important;
}
</style>
